import { useContext, useRef, createRef } from 'react';

import web3 from '../../../connection/web3';
import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import VNDContext from '../../../store/vnd-context';
import MarketplaceContext from '../../../store/marketplace-context';
import { formatPrice } from '../../../helpers/utils';
import eth from '../../../img/eth.png';
import MyCard from '../../materials/MyCard';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import MyCardForSell from '../../materials/MyCardForSell';
import ServerDataContext from '../../../store/server-context';
const NFTCollectionForSell = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const serverP = useContext(ServerDataContext);
  const marketplaceCtx = useContext(MarketplaceContext);

 
  return(
    <div className="row ">
      <h1>Selling</h1>
      <Grid container xs={12} sm={12} md={12} spacing={{ xs: 2, md: 2 }} >
      {
        serverP.nftSelling.map((NFT, key) => {
            const index = NFT.offer.offerId
            // balanceOf
            return(
              <Grid item xs={12} sm={6} md={3} key={index}>
                <MyCardForSell dataNFT={NFT}>
                </MyCardForSell>
              </Grid>
              
            );
          })
        }
        </Grid>
        <Backdrop open={(!serverP.nftSelling && serverP.nftSelling != [])}>
                   <CircularProgress  />
          </Backdrop>
    </div>
  );
};

export default NFTCollectionForSell;