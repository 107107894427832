import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import web3 from '../../connection/web3';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MarketplaceContext from '../../store/marketplace-context';
import { formatPrice } from '../../helpers/utils';
export default function ModalAccount() {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const web3Ctx = useContext(Web3Context);
  const marketCtx = useContext(MarketplaceContext);
  const web32 = web3
  let etherscanUrl;

  if(web3Ctx.networkId === 3) {
    etherscanUrl = 'https://ropsten.etherscan.io'
  } else if(web3Ctx.networkId === 4) {
    etherscanUrl = 'https://rinkeby.etherscan.io'
  } else if(web3Ctx.networkId === 5) {
    etherscanUrl = 'https://goerli.etherscan.io'
  } else {
    etherscanUrl = 'https://etherscan.io'
  }


  let ac_address = '';
  let ac_balance = 0;
  if(web3Ctx.account) {
    ac_address = web3Ctx.account.substring(0,5)+"..."+web3Ctx.account.substring(35,42);
    if(marketCtx.contract) {
      ac_balance = formatPrice(marketCtx.balance); ;
    }
  }
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <a 
        className="nav-link small address-acc-btn "
        onClick={handleClickOpen}
        rel="noopener noreferrer" style={{color:'white'}}>
            {ac_address}
      </a>
      <Dialog
        open={open}
        fullWidth={'true'}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" sx={{ width: '100%', bgcolor: 'white',color:'black' }} >
        <Typography variant="div" gutterBottom component="div">
            Account Info
        </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: '100%', bgcolor: 'white' , padding:4,color:'black'}}>
        
        <Typography variant="h6" gutterBottom component="div" >
                   Address : {web3Ctx.account}
        </Typography>
        <Typography variant="h6" gutterBottom component="div">
                   Balance : {ac_balance} ETH
        </Typography>
        <Box height={20} />
        <div className='btn-account'>
            <Link onClick={handleClose} className="nav-link text-center " style={{color:'black', width:'100%'}}  to="/ad-nft">
              AD Your Card
              <NavigateNextIcon sx={{ fontSize:'38px'}} />
            </Link>
        </div>
        <Box height={15} />
        </DialogContent>
      </Dialog>
    </div>
  );
}