import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';

export default function ModalConnect() {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const web3Ctx = useContext(Web3Context);
  const { enqueueSnackbar } = useSnackbar();
  const connectWalletHandler = async() => {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch(error) {
      let variant = "warning";
      enqueueSnackbar("Can not connect wallet, please try again. ", { variant });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <Button size='large' onClick={handleClickOpen}  variant="outlined">Connect Wallet</Button>
      <Dialog
        open={open}
        id="dialog-convert"
        // sx={{background:'white',color:'black'}}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" sx={{background:'white',color:'black'}}>
         CONNECT WALLET 
        </DialogTitle>
        <DialogContent sx={{background:'white',color:'black'}}>
          <DialogContentText id="alert-dialog-description">
            <div className='box-metamake' onClick={connectWalletHandler}>
              <img src="https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png" height="40px" style={{paddingRight:'15px'}} />  METAMAKS
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

   

    </div>
  );
}