import { useReducer } from 'react';

import Web3Context from './web3-context';

const defaultWeb3State = {
  account: null,
  networkId: null
};

const web3Reducer = (state, action) => {
  if(action.type === 'ACCOUNT') {
    return {
      account: action.account,
      networkId: state.networkId
    };
  } 
  
  if(action.type === 'NETWORKID') {
    return {
      account: state.account,
      networkId: action.networkId
    };
  }
  
  return defaultWeb3State;
};

const Web3Provider = props => {
  const [web3State, dispatchWeb3Action] = useReducer(web3Reducer, defaultWeb3State);
  
  const loadAccountHandler = async(web3) => {
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0];
    dispatchWeb3Action({type: 'ACCOUNT', account: account});
    return account;
  };

  const loadNetworkIdHandler = async(web3) => {
    const networkId = await web3.eth.net.getId();
    dispatchWeb3Action({type: 'NETWORKID', networkId: networkId});
    return networkId;   
  };
  
  const addNetWorkHandler = async (network = '') => {
   
    if(network == 'matic') {

      if(process.env.REACT_APP_MODE == 'TEST'){
        return await window.ethereum.request({ 
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: "0x13881", // A 0x-prefixed hexadecimal string
              chainName: 'mumbai',
              nativeCurrency: {
                name: "MATIC",
                symbol: "MATIC", // 2-6 characters long
                decimals: 18,
              },
              rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
              blockExplorerUrls:['https://mumbai.polygonscan.com/']
            }
          ]
         });
      }else{
          
       return await window.ethereum.request({ 
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: "0x89", // A 0x-prefixed hexadecimal string
            chainName: 'Polygon Mainnet',
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC", // 2-6 characters long
              decimals: 18,
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls:['https://polygonscan.com/']
          }
        ]
       });
      }
      //test
      

    }else{
      let chainID = '0x5';
      if(process.env.REACT_APP_MODE == 'TEST'){
         chainID = '0x5';
      }else{
        chainID = '0x1';
      }
      return await window.ethereum.request({ 
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainID }],
        // main
        // params: [{ chainId: '0x1' }],
       });
    }
    
    }

  const web3Context = {
    account:web3State.account,
    networkId: web3State.networkId,
    loadAccount: loadAccountHandler,
    loadNetworkId: loadNetworkIdHandler,
    addNetWork: addNetWorkHandler,
  };
  
  return (
    <Web3Context.Provider value={web3Context}>
      {props.children}
    </Web3Context.Provider>
  );
};

export default Web3Provider;