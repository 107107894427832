import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import VNDContext from '../../store/vnd-context';
import { useContext, useState } from 'react';
import web3 from '../../connection/web3';
import Web3Matic from '../../connection/Web3Matic';
import callApi, { addTransactionServerB, checkCodeRef } from '../../api';
import { Alert, Backdrop, Checkbox, CircularProgress, DialogActions, DialogTitle, IconButton, Link, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPrice, formatUSD, validateEmail } from '../../helpers/utils';
import ServerDataContext from '../../store/server-context';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';
import AddCardIcon from '@mui/icons-material/AddCard';
import CloseIcon from '@mui/icons-material/Close';
import ModalGuideBuy from './ModalGuideBuy';
import Step3 from './Step3';
import ModalGuideBuyOld from './ModalGuideBuyOld';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ModalGuideVideo from './ModalGuideVideo';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 700,
  // Width: '100%',
  width:'95%',
  maxHeight: '90vh',
  overflow: 'auto',
  bgcolor: 'white',
  color:'black',
  borderRadius:'20px',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
};


export default function BasicModal(props) {
  const navigate = useNavigate();

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const VNDContextCtx = useContext(VNDContext);
  const serverP = useContext(ServerDataContext);
 
  const [loadingBuy, setloadingBuy] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [codeDisabel, setcodeDisabel] = useState(false);
  const [buying, setcbuying] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState(1);
  const [amountIsValid, setAmountIsValid] = useState(false);
  const [enteredCodeRef, setEnteredCodeRef] = useState('');
  const [enteredEmail, setEmail] = useState('');
  const [vailEmail, setVailEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorCodeRef, setErrorCodeRef] = useState(null);
  const [vailBalance, setVailBlance] = useState(false);
  const [vailMatic, setVailMatic] = useState(false);
  const [balanceMatic, setBalanceMatic] = useState(0);
  const [stepStart, setStepStart] = useState(true);
  const [showVideo, setVideo] = useState(false);
  const [stepCurrent, setStepCurrent] = useState(1);

  const { enqueueSnackbar } = useSnackbar();
  const codeRefDl = 'isaac0000000'
  const addTransactions = (data)=>{
    return callApi('save/history_transaction','POST',data);
  }

  const enteredAmountHandler = (event) => {
    let amount =  parseInt(event.target.value) ? parseInt(event.target.value) : 0;
    setEnteredAmount(amount);
    if(amount > parseInt(props.dataNFT.available) || amount == 0){
      setAmountIsValid(true)
      setVailBlance(true)
    }else{
      setAmountIsValid(false);
      setVailBlance(false)
    }

  };
const openVideo = () =>{
  if(showVideo) {
    setVideo(false);
    
  }else{
    setVideo(true);
    // setOpen(false);
  }
  
}

const yourCardLink = () =>{
  navigate('/your-card')
}
const backLink = () =>{
  navigate('/')
}
const swtichEth = () => {
  web3Ctx.addNetWork();
}

  const enteredCodeRefHandler = (event) => {
    setEnteredCodeRef(event.target.value);
  };
  const enteredEmailHandler = (event) => {
    // setVailEmail()
    if(validateEmail(event.target.value)){
      setVailEmail(true)
    }else{
      setVailEmail(false);
    }
    setEmail(event.target.value);
  };
  // console.log('vailEmail',vailEmail);
  const checkCodeRefHandler = (event) => {
    setcodeDisabel(event.target.checked);
    if(event.target.checked){
      setEnteredCodeRef(codeRefDl);
    }
  };
  // const search = useLocation().search;
  // const referer_code = new URLSearchParams(search).get('referer_code');
  // if(referer_code){
  //   setEnteredCodeRef(referer_code);
  // }
 
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if(!buying){
      setOpen(false);
    }
  }
  
  const search = useLocation().search;
  const getMatic = async ()=>{
      const matic = await  Web3Matic.eth.getBalance(web3Ctx.account);
      setBalanceMatic(formatPrice(matic))
      if(formatPrice(matic) > 0.002) { 
        setVailMatic(false)
      }else{
        setVailMatic(true)
      }
      // console.log('vailMatic',vailMatic);
  }
    // setTimeout(async() => {
    //   await getMatic();
    // }, 5000);
  // const ss
  const init = async ()=>{

    
    // console.log('setVailMatic',vailMatic);
    if(props.dataNFT.price * enteredAmount > formatPrice(marketplaceCtx.balance)){
      setVailBlance(true)
    }else{
      setVailBlance(false);
    }
    
    await getMatic();
  
    const referer_code = new URLSearchParams(search).get('referer_code');
    if(referer_code){
      setEnteredCodeRef(referer_code);
    }
  }

const checkStep1 = async () => {
  if(enteredAmount.length == 0 || enteredAmount < props.amount ){
    return false;
  }
  if(!vailEmail){
    // setVailEmail(false);
    setErrorEmail(true);
    let variant = "error"
    enqueueSnackbar('Invalid Email !!!',{variant})
    return false;
  }else{
    setErrorEmail(false);
  }

  // if(props.dataNFT.price * enteredAmount > formatPrice(marketplaceCtx.balance)){
  //   let variant = "error"
  //   enqueueSnackbar('ETH balance is not enough  !!!',{variant})
  //   return false;
  // }

  if(codeDisabel) {
    setEnteredCodeRef(codeRefDl);
  }
  setloadingBuy(true);
  let checkCodeEd = await checkCodeRef(enteredCodeRef)
  if(checkCodeEd != 1) {
    let variant = "error"
    enqueueSnackbar('Invalid referral code !!!',{variant})
    setloadingBuy(false);
    setErrorCodeRef(true);
    return false;
  }else{
    setErrorCodeRef(false);
  }
  setloadingBuy(false);
  return true;
}

  const nextStep = (step) =>{
    setStepCurrent(step)
  }
const nextStep1 = () => {
  
  setStepCurrent(1);
}

const nextStep2 = async () => {
  if( await checkStep1()) {
    setStepCurrent(2);
  }
  
}
const nextStep3 = () => {
  setStepCurrent(3);
}
const nextStep4 = () => {
  if(!allowed) {
    approveHandler();
  }else{
    buyByweb3();
  } 
}

  React.useEffect(() => {
    init()
    }, [])
    

  if(VNDContextCtx.contract && marketplaceCtx.contract)
  VNDContextCtx.contract.methods
    .allowance(web3Ctx.account,marketplaceCtx.contract.options.address).call().then(allowance => {
      // console.log('allowance',allowance);
      if(allowance > 0){
        setAllowed(true)
        // setStepStart(false);
      }else{
        setAllowed(false);
        // setStepStart(true);
      }
    })

  const buyByweb3 = async () => {
    if(enteredAmount.length == 0 || enteredAmount < props.amount ){
      return;
    }
    if(!vailEmail){
      let variant = "error"
      enqueueSnackbar('Invalid Email !!!',{variant})
      return;
    }
 
    if(props.dataNFT.price * enteredAmount > formatPrice(marketplaceCtx.balance)){
      let variant = "error"
      enqueueSnackbar('ETH balance is not enough  !!!',{variant})
      return;
    }

    if(codeDisabel) {
      setEnteredCodeRef(codeRefDl);
    }
    setloadingBuy(true);
    let checkCodeEd = await checkCodeRef(enteredCodeRef)
    if(checkCodeEd != 1) {
      let variant = "error"
      enqueueSnackbar('Invalid referral code !!!',{variant})
      setloadingBuy(false);
      return;
    }
    marketplaceCtx.contract.methods.fillOffer(props.dataNFT.offer.offerId,enteredAmount )
    .send({ from: web3Ctx.account   })
    .on('transactionHash', (hash) => {
      // setloadingBuy(true);
      setcbuying(true);
    })
    .on('receipt', async (receipt) => {
      //load balance
      marketplaceCtx.loadUserBalance(marketplaceCtx.contract,web3Ctx.account);
      // luu log
          const dataPOst = {
            data: {
                "transtions_id": receipt.transactionHash,
                "offer_id": props.dataNFT.offer.offerId,
                "address_from": web3Ctx.account,
                "address_to": marketplaceCtx.contract._address,
                "data": props.dataNFT,
                "buy_amount": enteredAmount,
                "buy_price": props.dataNFT.price,
                "buy_usd" : props.dataNFT.price * enteredAmount * serverP.ethPirce,
                "type": "buy",
                "email": enteredEmail,
                "code_ref": enteredCodeRef
                }
        };

          if(codeDisabel) {
            dataPOst.data.code_ref = codeRefDl;
          }
          //load collection
          const nftOfAccount = await collectionCtx.loadNFTofAccount(collectionCtx.contract, web3Ctx.account);
          collectionCtx.loadCollection(collectionCtx.contract, nftOfAccount, web3Ctx.account);  
          addTransactions(dataPOst).then(_res => {
            // update selling
            setloadingBuy(false);
            setcbuying(false);
            marketplaceCtx.contract.methods.offers(props.dataNFT.offer.offerId).call().then(res =>{
            
              const dataPost = {
                data:{
                  "item_name": props.dataNFT.title,
                  "item_amount": res.amount,
                  "item_amountsold": res.amountsold,
                  "item_price": res.price,
                  "item_id": res.id,
                  "item_meta_data": props.dataNFT,
                  "item_img": props.dataNFT.img,
                  "item_transaction": props.dataNFT.dataServer.item_transaction,
                  "item_owner":res.user,
                  "item_offer_id": res.offerId,
                  "item_offer_data": res,
                  "item_cancel": (res.cancelled) ? 1 : 0,
                  "item_filled": (res.fulfilled) ? 1 : 0,
                  "_id": props.dataNFT.dataServer._id
                }
              } 
              callApi('save/nft_selling','POST',dataPost).then(res =>{
                serverP.loadNFTselling();
                setStepCurrent(4);
                let variant = "success"
                enqueueSnackbar('You Buy Card Success !!!',{variant})
              
              })
            })
          });
      // add data transaction to server B
      addTransactionServerB(
         enteredEmail,
         receipt.transactionHash,
         enteredCodeRef,
         web3Ctx.account,
         serverP.ethPirce,
         props.dataNFT.price,
         marketplaceCtx.contract._address,
         enteredAmount,
         enteredAmount * props.dataNFT.price,
          0
      ).then(ress => {
        console.log('resss add severB', ress);
      } )
    })
    .on('error', (error) => {
      setloadingBuy(false);
      setcbuying(false);
      handleClose();
      marketplaceCtx.setMktIsLoading(false);
    });  
  }
 

  const approveHandler = async (event) => {    

    if(enteredAmount.length == 0 || enteredAmount < props.amount ){
      return;
    }
    const enteredPrice = web3.utils.toWei('999999999999999999', 'ether');
    VNDContextCtx.contract.methods
      .approve(marketplaceCtx.contract.options.address,enteredPrice)
      .send({ from: web3Ctx.account })
      .on('transactionHash', (hash) => {
        setloadingBuy(true);
        setcbuying(true);
      }).on('receipt',  (receipt) => {
        setAllowed(true)
         setloadingBuy(false);
         setcbuying(false);
         let variant = "success"
         enqueueSnackbar('Apporve success !!!',{variant});
         buyByweb3();
      });
  };

 const upStart = () =>{
  setStepStart(false);
 }


  return (
    <div>
      <Button variant="contained" color="warning" 
      style={{width:"200px",fontSize:"23px",borderRadius:10, height:"60px"}} onClick={handleOpen} > <div style={{color:'white',fontWeight:800}}>BUY</div> </Button>
      <Modal id="modal222"
        open={open}
        onClose={handleClose}
      >
        <Box sx={style} >
        {/* step start */}
     
        {stepStart && 
          <div>
            <div  sx={{background:'white',color:'black'}}>
              <Typography  variant="h5" sx={{fontWeight:'bold'}} >
                              Do you want to buy this card?
                              <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                  sx={{
                                  position: 'absolute',
                                  right: 10,
                                  top: 12,
                                  color: 'black',
                                  }}
                              >
                          <CloseIcon />
                          </IconButton>
                  </Typography>
          </div>
          
            <ModalGuideBuy></ModalGuideBuy>
        {showVideo &&
                <ModalGuideVideo ></ModalGuideVideo>
              }
        <DialogActions sx={{bgcolor:'white', justifyContent:'center'}}>
            <Stack justifyContent={'center'} spacing={1} direction={{md:'row', xs:'column'}}>
              <Button variant='contained' onClick={openVideo} size='large'  color={'warning'} >
                      <OndemandVideoIcon sx={{marginRight:1}}></OndemandVideoIcon> Guideline
              </Button>             

              
             
               <Button variant='contained' onClick={upStart} size='large'  sx={{minWidth:'210px', bgcolor: '#0d6efd',color: 'white'}} color={'primary'} >Go</Button>
            </Stack>
        </DialogActions>
          </div>
        }


        {!stepStart && 
          <div>
              <Typography  variant="h4"  >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                              position: 'absolute',
                              right: 10,
                              top: 12,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                      <CloseIcon />
                    </IconButton>
              </Typography>
           
                <div className="row p-1" >
                       <div className="col-md-12  box-steps">
                         <Stepper activeStep={stepCurrent - 1 } alternativeLabel >
                          <Step key="step1"  >
                            <StepLabel ></StepLabel>
                          </Step>
                          <Step key="step2"  sx={{height:'50px'}}  >
                            <StepLabel sx={{height:'50px'}} >  </StepLabel>
                          </Step>
                          <Step key="step3"  sx={{height:'50px'}}  >
                            <StepLabel sx={{height:'50px'}} >  </StepLabel>
                          </Step>
                        </Stepper>
                            {stepCurrent == 1 &&
                              <Typography   variant="h5" sx={{fontWeight:'bold'}} >
                              Step {stepCurrent}: Payment confirmation
                              </Typography>
                            }
                            {stepCurrent == 2 &&
                              <Typography   variant="h5" sx={{fontWeight:'bold'}} >
                                Step {stepCurrent}: Check gas charge
                              </Typography>
                            }
                            {stepCurrent == 3 &&
                              <Typography   variant="h5" sx={{fontWeight:'bold'}} >
                              Step {stepCurrent}: Unblock Ethereum & Buy  
                              </Typography>
                            }
                           
                        
                       </div>
                       {stepCurrent == 1 &&
                       <div className='row'>
                          <div className="col-md-12 p-3">
                          <Typography  className="mb-2" variant="h5" >
                          <label style={{fontSize:'0.7em'}}>Price: {props.dataNFT.price} ETH </label> 
                          </Typography>
                          <Typography  className="mb-2"  variant="h5" >
                          <label style={{fontSize:'0.7em'}}>Available:  {props.dataNFT.available}</label>
                          </Typography>
                          <Typography  className="mb-2"  variant="h5" >
                          <label style={{fontSize:'0.7em',paddingRight:'10px'}}>Total: </label> 
                          {/* {props.dataNFT.price} x {enteredAmount} = */}
                          {(props.dataNFT.price * enteredAmount).toFixed(2)} ETH  <i>({formatUSD(props.dataNFT.price * enteredAmount * serverP.ethPirce)})</i> 
                          </Typography>
                        </div>

                        <div className="col-12 col-md-6 ">
                    <label style={{fontSize:'0.7em'}}>Quantity:</label>
                        <input 
                        type="mumber" 
                        // disabled={!allowed}
                          value={enteredAmount}
                          onChange={enteredAmountHandler}
                        className="form-control" placeholder="amount"  />
                    </div>
                    <div className="col-12 col-md-6">
                    <label style={{fontSize:'0.7em'}}>Email:</label>
                      <input type="text" 
                          className={errorEmail == true ? 'form-control input-danger' : 'form-control'}
                          value={enteredEmail}
                          // disabled={!c}
                          onChange={enteredEmailHandler}
                        // className="form-control"
                         placeholder="Email"  />
                    </div>
                    <Box height={10} />
                    <div className="col-12 ">
                      <input type="text" 
                          value={enteredCodeRef}
                          disabled={codeDisabel }
                          onChange={enteredCodeRefHandler}
                          // className="form-control" 
                          className={errorCodeRef == true ? 'form-control input-danger' : 'form-control'}
                          placeholder="Referral code"  />
                        <label> <Checkbox checked={codeDisabel} onChange={checkCodeRefHandler}  style={{color:'red'}} /> No referral code.</label>
                    </div>
                    <Box height={10}></Box>
                      {/* khong du ETH*/}
                      {vailBalance &&
                       <Typography   variant="p"  sx={{color:'red'}} >
                          <i> *Not enough Ethereum <img width="10" src="https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg" /> to trade, <Link href='#' onClick={swtichEth} sx={{color:'red' , fontWeight:'bold',textDecorationColor:'red'}} >top up now</Link></i> 
                       </Typography>
                      }
                    {/* step 1 action */}
                    <Box sx={{ width: '100%' }}>
                      <Box height={10}></Box>

                    <div className='row'>
                      <div className="col-md-12 text-right " style={{textAlign:'right'}}>
                        <button 
                        className="buttonNext"
                        onClick={nextStep2}
                          size="large" >
                        <label> Next </label> 
                        </button>
                      
                      </div>
                    </div>
                    </Box>
                    
                      </div>
                       }
                     {stepCurrent == 2 &&
                      <div>
                         <ModalGuideBuyOld data={balanceMatic}></ModalGuideBuyOld>
                         <div className='row'>
                         <Stack spacing={1} justifyContent={'right'} direction={{xs:'column', md:'row'}}>
                           <button 
                            className="buttonNext buttonPre"
                            onClick={nextStep1}
                              size="large" >
                            Back
                            </button>

                            <button 
                            className="buttonNext"
                            disabled={vailMatic}
                            onClick={nextStep3}
                              size="large" >
                             Next
                            </button>
                           </Stack>
                        </div>     
                    </div>}

                    {stepCurrent == 3 &&
                      <div>
                         <Step3></Step3>
                         <Box height={10}></Box>
                            {/* khong du ETH*/}
                            {vailBalance &&
                            <Typography   variant="p"  sx={{color:'red'}} >
                                <i> *Not enough Ethereum <img width="10" src="https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg" /> to trade, <Link href='#' onClick={swtichEth} sx={{color:'red' , fontWeight:'bold',textDecorationColor:'red'}} >top up now</Link></i> 
                            </Typography>
                            }
                         <Box height={30}></Box>
                         <div className='row'>
                           <Stack spacing={1} justifyContent={'right'} direction={{xs:'column', md:'row'}}>
                           <button 
                            className="buttonNext buttonPre"
                            onClick={nextStep2}
                              size="large" >
                            Back
                            </button>

                            

                            <button 
                            className="buttonNext"
                            disabled={vailBalance}
                            onClick={nextStep4}
                              size="large" >
                                {!allowed ? 'Unlock & Buy' : 'Buy'}
                            </button>
                           </Stack>
                        </div>     
                    </div>}
                    {stepCurrent == 4 &&
                      <div>
                              <div className="row p-1" >
                                <Box height={10}></Box>
                                <div className="col-md-12 text-center">
                                 
                                    <h2>SUCCESSFUL TRANSACTION</h2>
                                      <Stack spacing={1} direction={'row'}  alignItems={{md:'center'}}>
                                        <div style={{fontSize:'1.2em'}}> Congratulations on owning your flat. Please check it out at 'My Card'!</div>
                                      </Stack>
                                 
                                </div>
                              </div>
                         <Box height={45}></Box>
                         <div className='row'>
                           <Stack spacing={1} justifyContent={'center'} direction={{xs:'column', md:'row'}}>
                           <button 
                            className="buttonMyCard "
                            onClick={yourCardLink}
                               >
                            My Card 
                            </button>

                            <button 
                            className="buttonNext"
                            onClick={backLink}
                              >
                             Buy More
                            </button>
                           </Stack>
                        </div>     
                    </div>}
                </div>
                <Backdrop open={loadingBuy}>
                        <CircularProgress  />
                </Backdrop>
          </div>
        }

        </Box>
     
       
       

      </Modal>
    </div>
  );
}