import * as React from 'react';

import { useContext  } from 'react';
import {  Spinner } from 'react-bootstrap';
import CollectionContext from '../../store/collection-context';
import MintForm from '../Content/MintNFT/MintForm';
import NavLeft from '../Layout/NavLeft';
import ADSelling from './ADSelling';
import ADYourCard  from './ADYourCard';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MarketplaceContext from '../../store/marketplace-context';
import Web3Context from '../../store/web3-context';
import { useNavigate } from 'react-router-dom';
import { CheckOwner } from '../../helpers/lib';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  

const ADNFT = () => {
 


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const collectionCtx = useContext(CollectionContext);
    
    const marketplaceCtx = useContext(MarketplaceContext);
    const web3Ctx =  useContext(Web3Context);
    const navigate = useNavigate();
    if(marketplaceCtx.owner && web3Ctx.account) {
      if(!CheckOwner(marketplaceCtx,web3Ctx)){
        navigate('/login');
      }
    }
   
  return(
    <Box sx={{ display: 'flex' ,height:'100vh'}}  >
         <NavLeft></NavLeft>
          <div className='container'  component="main" sx={{ flexGrow: 1, p: 2 }}>
            <Box height="80px"></Box>
            <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="List Selling" {...a11yProps(1)} />
                <Tab label="List Card"  />
                <Tab label="Mint"  />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
            { <ADSelling />}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ADYourCard />
            </TabPanel>
            <TabPanel value={value} index={2}>
            { <MintForm />}
            </TabPanel>
            </Box>
                {collectionCtx.nftIsLoading && <Spinner />}
          </div>
        
    </Box>
  );
};

export default ADNFT;