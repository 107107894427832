import * as React from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import Market from '../Content/Market/Market';
import { useContext  } from 'react';
import NavHeader from '../Layout/NavHeader';
// import Navbar from '../components/Layout/Navbar';
const Home = () => {

  const marketplaceCtx = useContext(MarketplaceContext);
  return(
    
    <main role="main" className=" ">
      <div className=''>
          <NavHeader></NavHeader>
      </div>
      <div className='container'>
          <div className ="col-lg-12 justify-content-center text-center mb-2">
            {<Market />}
            </div>
      </div>
    </main>
  );
};

export default Home;