import * as React from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import { useContext  } from 'react';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import callApi from '../../api';
import { Alert, AlertTitle, Card, Chip, CssBaseline, Grid, Typography } from '@mui/material';
import NavLeft from '../Layout/NavLeft';
import { Box } from '@mui/system';
import { CheckOwner } from '../../helpers/lib';
import Web3Context from '../../store/web3-context';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import { formatUSD } from '../../helpers/utils';
import ADAddressInfo from "../materials/ADAddressInfo";

// let totalTrade = 0;  
let totalValuePirce = 0;  
let columns = [
    { field: 'id', headerName: 'id', width: 50 },
    { field: 'transtions_id', headerName: 'transtions_id', width: 250 },
    { field: 'address_from', headerName: 'address_from', width: 150 },
    { field: 'type', headerName: 'type', width: 100 },
    { field: 'code_ref', headerName: 'code_ref', width: 150 },
    { field: 'buy_amount', headerName: 'buy_amount', width: 150 },
    { field: 'buy_price', headerName: 'buy_price', width: 150 },
    { field: 'buy_usd', headerName: 'buy_usd', width: 150 },
    { field: '_created', headerName: '_created', width: 150 },
  ];
  const getListTradeForCard = () => {
    return callApi('get/history_transaction')
  }
 

const ADDashboard = () => {



  const [rows, setRows] = React.useState([]);  
  const [loadding, setLoading] = React.useState(false);  
  const [totalTrade, setTotalTrade] = React.useState(0);  
  const [totalUSD, setTotalUSD] = React.useState(0);  
  const [totalETH, setTotalETH] = React.useState(0);  
  const [totalAmount, setTotalAmount] = React.useState(0);  
  const [ownered, setOnwnered] = React.useState(false);  
  const marterCtx =  useContext(MarketplaceContext);
  const web3Ctx =  useContext(Web3Context);
  const navigate = useNavigate();

  if(marterCtx.owner && web3Ctx.account) {
    if(!CheckOwner(marterCtx,web3Ctx)){
      navigate('/login');
    }
  }

  React.useEffect(() => {

    if(marterCtx.owner && web3Ctx.account) {
      if(!CheckOwner(marterCtx,web3Ctx)){
        navigate('/login');
      }
      setOnwnered(CheckOwner(marterCtx,web3Ctx));
    }
   
    setLoading(true);
    getListTradeForCard().then(async res => {
      let _totalUsd = 0;
      let _totalEth = 0;
      let _totalAmount = 0;
      if(!res.data) {
          return [];
      }
      const rowsT = res.data.entries.map( (e,index) =>{
           e.id = index;
           _totalUsd += parseFloat(e.buy_usd);
           _totalEth += + parseFloat(e.buy_price);
           _totalAmount += + parseFloat(e.buy_amount);
           return {...e}
       });
       console.log('_totalUsd',_totalUsd);  
       setTotalTrade(res.data.total);
       setRows(rowsT);
       setTotalUSD(_totalUsd)
       setTotalAmount(_totalAmount)
       setTotalETH(_totalEth)
       setLoading(false);
     })

  }, [getListTradeForCard]) 
  return(
    <div >
       {ownered && 
       <Box sx={{ display: 'flex' }}>
         <NavLeft></NavLeft>
          <div className="container" component="main" sx={{ flexGrow: 1, p: 2 }}>
              <main role="main" className="col-lg-12 ">
                <Box height="100px"></Box>
                <ADAddressInfo />
                <Box height="20px"></Box>
                  <Card  className="p-3" sx={{borderRadius:2,border:1,borderColor:"rgb(30, 73, 118)",  background:'rgb(6 43 80)'}}
                   >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item  xs={6} sm={6} md={3} >
                            <Typography variant="h6" gutterBottom component="div">
                            Total trade:
                            </Typography>
                            <Typography variant="h4" gutterBottom >
                                {totalTrade}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom component="div">
                        Total Value:
                        </Typography>
                        <Typography variant="h4" gutterBottom >
                            {totalETH.toFixed(2)}  ETH
                        </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom component="div">
                        Total Value USD:
                        </Typography>
                        <Typography variant="h4" gutterBottom >
                            {formatUSD(totalUSD) }  
                        </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom component="div">
                        Total NFT Sold:
                        </Typography>
                        <Typography variant="h4" gutterBottom >
                        {totalAmount}
                        </Typography>
                        </Grid>
                      </Grid>
                  </Card>
                
                <Box height="30px"></Box>
                <h2>Transactions Trade</h2>
                <Box height="20px"></Box>
                <div style={{ height: 600, width: '100%',borderRadius:2,border:1,borderColor:"rgb(30, 73, 118)",  background:'rgb(6 43 80)' }}>
                  <DataGrid  
                  pageSize={25}
                  sx={{
                  fontSize:"1.2em", 
                  // border:0,
                 
                  '& .MuiDataGrid-cell': {
                      border: '0px',
                      fontSize: '17px'
                    },
              }} 
              rows={rows} columns={columns} />
                </div>
                
              </main>
          </div>
      </Box>}
    </div>
  );
};

export default ADDashboard;