import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';
import { DialogActions, IconButton, Link, Modal, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CloseIcon from '@mui/icons-material/Close';
import { LionPlayer } from 'lion-player';
const SOURCES = [
  {
    src: '/video/guide_buy.mp4',
    type: 'video/mp4',
  },
  // {
  //   src: 'https://bitmovin-a.akamaihd.net/content/playhouse-vr/mpds/105560.mpd',
  //   type: 'application/dash+xml',
  // }
];
const styleBox = {
 
  
  width:'100%',
  // maxHeight: '100vh',
  minHeight:'500px',
  // overflow: 'auto',
  bgcolor: 'white',
  color:'black',
  borderRadius:'20px',
  border: '2px solid #000',
  boxShadow: 24,
  p: 0,
 
};

export default function ModalGuideVideo() {
  const [open, setOpen] = React.useState(true);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
      <DialogContent sx={{background:'white',color:'black'}}>
        <LionPlayer  sources={SOURCES} autoplay="muted" />
      </DialogContent>
    </div>
  );
}