import { useReducer } from 'react';
import Web3 from 'web3';
import callApi from '../api';
import { formatPrice, formatPriceEth } from '../helpers/utils';

import ServerDataContext from './server-context';

const defaultDataState = {
  nftSelling: [],
  ethPirce: 0,
};
const listSelling = () => {
  let filter = {
    filter:{
      "item_cancel":0,
      // "item_filled":0,
    },
    sort:{
      "item_amountsold":-1,
      "_created":-1,
    }
  }
  return  callApi('get/nft_selling','post',filter);
  }

const serverReducer = (state, action) => {
  if(action.type === 'LOADSELL') { 
    return {
      nftSelling: action.nftSelling,
      ethPirce: state.ethPirce
    };
  }
  if(action.type === 'LOADPRICE') { 
    return {
      nftSelling: state.nftSelling,
      ethPirce: action.ethPirce
    };
  }
  return defaultDataState;
};

const ServerDataProvider = props => {


  const [serverState, dispatchServerAction] = useReducer(serverReducer, defaultDataState);

    const loadNFTsellingHandler = async() => {
    listSelling().then(res => {
      const rowTemp = res.data.entries.map( (e,index) =>{
        const amountsold = (e.item_amountsold == null) ? 0: e.item_amountsold
          return {
            amount : e.item_amount,
            available : parseInt(e.item_amount) - amountsold ,
            description : e.item_meta_data?.description,
            idFake : e.item_id.toString().padStart(4, "0"),
            offer: e.item_offer_data,
            img : e.item_img,
            offerId : e.item_offer_data.item_offer_id,
            price : formatPrice(e.item_offer_data.price).toFixed(2),
            title : e.item_meta_data?.title,
            dataServer: e,
          }
      });
      dispatchServerAction({type: 'LOADSELL', nftSelling: rowTemp});
    })
   
  };

  const loadPriceHandler = async() => {
    const web3 = new Web3("https://kovan.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd")
    const aggregatorV3InterfaceABI = [{ "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "description", "outputs": [{ "internalType": "string", "name": "", "type": "string" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "uint80", "name": "_roundId", "type": "uint80" }], "name": "getRoundData", "outputs": [{ "internalType": "uint80", "name": "roundId", "type": "uint80" }, { "internalType": "int256", "name": "answer", "type": "int256" }, { "internalType": "uint256", "name": "startedAt", "type": "uint256" }, { "internalType": "uint256", "name": "updatedAt", "type": "uint256" }, { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "latestRoundData", "outputs": [{ "internalType": "uint80", "name": "roundId", "type": "uint80" }, { "internalType": "int256", "name": "answer", "type": "int256" }, { "internalType": "uint256", "name": "startedAt", "type": "uint256" }, { "internalType": "uint256", "name": "updatedAt", "type": "uint256" }, { "internalType": "uint80", "name": "answeredInRound", "type": "uint80" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "version", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }]
    const addr = "0x9326BFA02ADD2366b30bacB125260Af641031331"
    const web3Eth = new web3.eth.Contract(aggregatorV3InterfaceABI, addr)
    let PriceEth;
    web3Eth.methods.latestRoundData().call().then((roundData) => {
        PriceEth = formatPriceEth(roundData.answer).toFixed(2)
        dispatchServerAction({type: 'LOADPRICE', ethPirce: parseFloat(PriceEth)});
        return PriceEth;
    })  
  }

  const serverContext = {
    nftSelling: serverState.nftSelling,
    ethPirce:serverState.ethPirce,
    loadNFTselling: loadNFTsellingHandler,
    loadPrice: loadPriceHandler,
  };
  
  return (
    <ServerDataContext.Provider value={serverContext}>
      {props.children}
    </ServerDataContext.Provider>
  );
};

export default ServerDataProvider;