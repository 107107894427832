
export const CheckOwner = (marterCtx,web3Ctx) => {
    if(marterCtx.owner && web3Ctx.account && marterCtx.owner == web3Ctx.account){
        return true;
    }
    return false;
};





