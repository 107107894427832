import * as React from 'react';
import ADNFTCollection from '../Content/NFTCollection/ADNFTCollection';
import NFTCollectionForSell from '../Content/NFTCollection/NFTCollectionForSell';
import { useContext  } from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import Web3Context from '../../store/web3-context';
import { useNavigate } from 'react-router-dom';
import { CheckOwner } from '../../helpers/lib';



const ADSelling = () => {



  return(
    <main role="main" className="col-lg-12 justify-content-center  mb-2">
        { <NFTCollectionForSell />}
    </main>
  );
};

export default ADSelling;