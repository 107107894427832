import * as React from 'react';
import { useContext, useRef, createRef ,useState} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Backdrop, CardMedia, CircularProgress } from '@mui/material';
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import web3 from '../../connection/web3';
import callApi from '../../api';
import ServerDataContext from '../../store/server-context';
import { useSnackbar } from 'notistack';



export default function MyCardForSell(props) {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const serverP = useContext(ServerDataContext);
  const { enqueueSnackbar } = useSnackbar();
  const [loadding, setLoadding] = useState(false);  
  const cancelHandler = (event) => {    

    marketplaceCtx.contract.methods.cancelOffer(props.dataNFT.offer.offerId).send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {

      marketplaceCtx.setMktIsLoading(true);
      setLoadding(true);
      const data__ = { ...props.dataNFT.dataServer}
      data__.item_cancel =1;
      const dataPost = {
        data:data__
      } 
      console.log('dataPost',dataPost);
      callApi('save/nft_selling','POST',dataPost).then(res =>{
        serverP.loadNFTselling();
       setLoadding(false);
       let variant = "success";
       enqueueSnackbar('Make offer success',{variant})
      })

    })
    .on('error', (error) => {
      setLoadding(false);
      let variant = "error";
      enqueueSnackbar('Something went wrong when pushing to the blockchain',{variant})
      marketplaceCtx.setMktIsLoading(false);
    });    
  };

  return (
    <Box  >
      <Card variant="outlined" sx={{display: 'flex',borderRadius:2,border:0, padding:1.2, background:'#28456e7d'}}>
      <CardMedia
            component="img"
            sx={{ width: 100 }}
            image={`${props.dataNFT.img}`}
            alt=""
        />
      <React.Fragment sx={{ display: 'flex', flexDirection: 'column', textAlign:'left' }}>
        <CardContent>
        <Typography sx={{ fontSize: 17,fontWeight:800,  }}  gutterBottom>
         #{props.dataNFT.idFake} 
        </Typography>
         <Typography sx={{ fontSize: 15, fontWeight: 800, textAlign:'center' }} color="text.primary" gutterBottom>
           {props.dataNFT.title}
        </Typography>

        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         Amount:  {props.dataNFT.offer.amount - props.dataNFT.offer.amountsold}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
         Price:  {props.dataNFT.price} ETH
        </Typography>
        <button onClick={cancelHandler}  className="btn btn-danger">CANCEL</button>
        </CardContent>
      </React.Fragment>
     
      </Card>
      <Backdrop open={loadding}>
                   <CircularProgress  />
          </Backdrop>
    </Box>
  );
}