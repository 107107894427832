import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import getPOSClient from '../../connection/POSClient';
import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Button, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import web3 from '../../connection/web3';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import MarketplaceContext from '../../store/marketplace-context';
import { formatPrice, formatUSD } from '../../helpers/utils';
import CachedIcon from '@mui/icons-material/Cached';
import AddCardIcon from '@mui/icons-material/AddCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ServerDataContext from '../../store/server-context';
import Web3Eth2 from '../../connection/Web3Eth2';
import Web3Matic from '../../connection/Web3Matic';
export default function ModalAccount() {
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [ac_balance_on_eth, set_ac_balance_on_eth] = React.useState(0);
  const [ac_balance, set_ac_balance] = React.useState(0);
  const web3Ctx = useContext(Web3Context);
  const marketCtx = useContext(MarketplaceContext);
  const serverP = useContext(ServerDataContext);
  const web32 = web3
  let etherscanUrl;
  
  if(web3Ctx.networkId === 3) {
    etherscanUrl = 'https://ropsten.etherscan.io'
  } else if(web3Ctx.networkId === 4) {
    etherscanUrl = 'https://rinkeby.etherscan.io'
  } else if(web3Ctx.networkId === 5) {
    etherscanUrl = 'https://goerli.etherscan.io'
  } else {
    etherscanUrl = 'https://etherscan.io'
  }


  let ac_address = '';
  // let ac_balance = 0;

  const getBalanceEthOnETH = async ( AC_address) =>{
   
    return await Web3Eth2.eth.getBalance(AC_address);
  }

  // const getBalanceEthOnMatic = async ( AC_address) =>{
   
  //   return await Web3Matic.eth.getBalance(AC_address);
  // }
  const getBalanceEthOnMatic = async (AC_address ) =>{
    
    const client =   await getPOSClient(web3Ctx.account);
    let erc20Token ;
    erc20Token = client.erc20(process.env.REACT_APP_ETH_ADDRESS_MATIC);
    if(AC_address) {
      return await erc20Token.getBalance(AC_address);
    }
    return -1;
    // console.log('balance On Matic',  balance);
  }

  if(web3Ctx.account) {
    ac_address = web3Ctx.account.substring(0,5)+"..."+web3Ctx.account.substring(35,42);
    // if(marketCtx.contract) {
    //   ac_balance = formatPrice(marketCtx.balance);   
    // }
  }
 
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const init = async () =>{
    // set_ac_balance_on_eth(0);
   const priceE2 = await getBalanceEthOnETH(web3Ctx.account);
   set_ac_balance_on_eth(formatPrice(priceE2));
   const priceEMatic = await getBalanceEthOnMatic(web3Ctx.account);
   set_ac_balance(formatPrice(priceEMatic));
 
  }

  const reLoadBalance = async () => {
    set_ac_balance_on_eth(0);
    set_ac_balance(0);
    
    const priceE2 = await getBalanceEthOnETH(web3Ctx.account);
    set_ac_balance_on_eth(formatPrice(priceE2));
    const priceEMatic = await getBalanceEthOnMatic(web3Ctx.account);
    set_ac_balance(formatPrice(priceEMatic));
 
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getFromEther = () =>{
    web3Ctx.addNetWork();
    handleClose2();
  }
  const handleClose2 = () => {
    setAnchorEl(null);
  };


  React.useEffect(async () => {
    init();
  },[])
  return (
    <div >
      <a 
        className="nav-link small address-acc-btn "
        onClick={handleClickOpen}
        rel="noopener noreferrer">
            {ac_address}
            <br/>
      </a>
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title" sx={{ width: '100%', }} >
          <Typography  gutterBottom component="div">
              Account Info
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ width: '100%', }}>

        <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }} >
        <Chip label={'Addrees: '+web3Ctx.account} variant="outlined" />
        <Button variant='outlined' color="info" onClick={reLoadBalance} startIcon={<CachedIcon />}  size="small" >Load balance</Button>
        </Stack>
        
        <Box height={15}></Box>
        <List  component="nav" aria-label="mailbox folders" sx={{border:'1px solid #bcbebf',borderRadius:2}}>
          <ListItem  
           secondaryAction = {
             <div>
            <IconButton edge="end" aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open2 ? 'true' : undefined}
            onClick={handleClick} >
              <MoreVertIcon />
             
            </IconButton>
             <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={open2}
             onClose={handleClose2}
           >
               <MenuItem onClick={getFromEther}>Get more from Ethereum</MenuItem>
               {/* <MenuItem onClick={handleClose2}>Buy on exchange</MenuItem> */}
           </Menu>
           </div>
          } >
            <ListItemAvatar>
             <img width="23" src="https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg" />
            </ListItemAvatar>
            <ListItemText primary="ETH"  secondary="Polygon" />
               <ListItemText primary={ac_balance} 
               secondary={formatUSD(serverP.ethPirce*ac_balance) }  
              />
        </ListItem>
          <Divider />
          <ListItem secondaryAction = {
             <div>
            <IconButton edge="end" aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open2 ? 'true' : undefined}
            // onClick={handleClick} 
            >
              <MoreVertIcon />  
             
            </IconButton>
             {/* <Menu
             id="basic-menu"
             anchorEl={anchorEl}
             open={open2}
             onClose={handleClose2}
           >
               <MenuItem onClick={getFromEther}>Get from Polygon</MenuItem>
               <MenuItem onClick={handleClose2}>Buy on exchange</MenuItem>
           </Menu> */}
           </div>
          } >
            <ListItemAvatar>
             <img width="23" src="https://storage.opensea.io/files/6f8e2979d428180222796ff4a33ab929.svg" />
            </ListItemAvatar>
            <ListItemText primary="ETH"  secondary="Ethreum" sx={{
            
              }}/>
               <ListItemText 
               primary={ac_balance_on_eth} 
               secondary={formatUSD(serverP.ethPirce*ac_balance_on_eth) }  
              />
          </ListItem>
        </List>

        <Box height={20} />
        <div className='btn-account'>
            <Link onClick={handleClose} className="nav-link text-center " style={{color:'#eee', width:'100%'}}  to="/your-card">
              Your Card
              <NavigateNextIcon sx={{ fontSize:'38px'}} />
            </Link>
           
        </div>
        <Box height={15} />
        </DialogContent>
      </Dialog>

   

    </div>
  );
}