import { useContext, useRef, createRef, useEffect } from 'react';

import web3 from '../../../connection/web3';
import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import VNDContext from '../../../store/vnd-context';
import MarketplaceContext from '../../../store/marketplace-context';
import { formatPrice } from '../../../helpers/utils';
import eth from '../../../img/eth.png';
import MyCard from '../../materials/MyCard';
import { Grid ,Box, CircularProgress} from '@mui/material';

const NFTCollection = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const VNDContextCtx = useContext(VNDContext);
  let balanceOf = 0;
  let allowance = 0;
  // collectionCtx.loadCollection(nftContract, nftOfAccount, account);  
  // console.log('1234', collectionCtx.collection);
  // useEffect( async () => {
  //   if(collectionCtx.nftContract) {
  //     const nftOfAccount = await collectionCtx.loadNFTofAccount(collectionCtx.nftContract, web3Ctx.account);
  //     collectionCtx.loadCollection(collectionCtx.nftContract, nftOfAccount, web3Ctx.account);  
  //     console.log('collectionCtx.collection',collectionCtx.collection);
  //   }
   
  // }, []); 

 
  return(
    <div className=" text-center">
     
      <Grid container spacing={2} xs={12}  sm={12}>
      {collectionCtx.collection &&
      collectionCtx.collection.map((NFT, key) => {
        // console.log(web3Ctx.account);
        if(NFT.owner != web3Ctx.account) return false;
        const index = marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id === NFT.id) : -1;
        const owner = index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
        const price = index !== -1 ? formatPrice(NFT.offer.price).toFixed(2) : null;
        NFT.key=key;
        NFT.price=price;
        NFT.owner=owner;
        // balanceOf
        return(
          <Grid item xs={12}  sm={3} key={index}>
            <MyCard dataNFT={NFT}>
            </MyCard>
          </Grid>
        );
      })}
      {!collectionCtx.collection &&
        <div className='col-md-12 text-center'>
          <CircularProgress  />
        </div>
         
      }
      {collectionCtx.collection && collectionCtx.collection.length == 0 &&
        <div className='col-md-12 text-center'>
        <p> You have not card. </p>
        </div>
      }
      </Grid>
      <Box sx={{ height: 100,}}></Box>
     </div>
  );
};

export default NFTCollection;