import { useState, useContext } from 'react';

import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
// import { NFTStorage, File } from 'nft.storage'



const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' });

const MintForm = () => {  
  const [enteredName, setEnteredName] = useState('');
  const [nameIsValid, setNameIsValid] = useState(true);
  const [loadding, setLoadding] = useState(false);  
  const [enteredAmount, setEnteredAmount] = useState('');
  const [amountIsValid, setAmountIsValid] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [enteredDescription, setEnteredDescription] = useState('');
  const [descriptionIsValid, setDescriptionIsValid] = useState(true);
  // const client = new NFTStorage({ token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGExMDQzMTE1RjUwMThjNzY4MjY5QTYwYzlEMEUwMTAxM2ZFNGY3MjciLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYzODc2NDQ1ODUwMCwibmFtZSI6InRlc3QxIn0.brPDnvJrk4EBXkgB1uqN3_Ek8s_js3l2_xgfoieZul8' })

  const [capturedFileBuffer, setCapturedFileBuffer] = useState(null);
  const [fileIsValid, setFileIsValid] = useState(true);

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);

 
  const enteredNameHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const enteredAmountHandler = (event) => {
    setEnteredAmount(event.target.value);
  };

  const enteredDescriptionHandler = (event) => {
    setEnteredDescription(event.target.value);
  };
  
  const captureFile = (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      setCapturedFileBuffer(Buffer(reader.result));     
    }
  };  
  
  const submissionHandler = (event) => {
    event.preventDefault();
    setLoadding(true);
    enteredName ? setNameIsValid(true) : setNameIsValid(false);
    enteredAmount ? setAmountIsValid(true) : setAmountIsValid(false);
    enteredDescription ? setDescriptionIsValid(true) : setDescriptionIsValid(false);
    capturedFileBuffer ? setFileIsValid(true) : setFileIsValid(false);

    const formIsValid = enteredName && enteredDescription && capturedFileBuffer;

    // Upload file to IPFS and push to the blockchain
    const mintNFT = async() => {
      // const metadatae = await client.store({
      //   name: 'Pinpie',
      //   description: 'Pin is not delicious beef!',
      //   image: new File(
      //     [capturedFileBuffer],
      //     'pinpie.jpg',
      //     { type: 'image/jpg' }
      //   ),
      // })
      // Add file to the IPFS
      const fileAdded = await ipfs.add(capturedFileBuffer);
      if(!fileAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }

    

      const metadata = {
        name: enteredName,
        description: enteredDescription,
        image:`https://ipfs.infura.io/ipfs/${fileAdded.path}`,
        // properties: {
        //   name: {
        //     type: "string",
        //     description: enteredName
        //   },
        //   amount: {
        //     type: "string",
        //     description: enteredAmount
        //   },
        //   description: {
        //     type: "string",
        //     description: enteredDescription
        //   },
        //   image: {
        //     type: "string",
        //     description: fileAdded.path
        //   }
        // }
      };

      const metadataAdded = await ipfs.add(JSON.stringify(metadata));
      if(!metadataAdded) {
        console.error('Something went wrong when updloading the file');
        return;
      }
      
      collectionCtx.contract.methods.addGameID(`https://ipfs.infura.io/ipfs/${metadataAdded.path}`, enteredAmount).send({ from: web3Ctx.account })
      .on('transactionHash', (hash) => {
        setLoadding(true);
      }) .on('receipt', async (receipt) => { 
        const nftOfAccount = await collectionCtx.loadNFTofAccount(collectionCtx.contract, web3Ctx.account);
        collectionCtx.loadCollection(collectionCtx.contract, nftOfAccount, web3Ctx.account);

        setLoadding(false);
        let variant = "success"
        enqueueSnackbar('Mint success',{variant})
      })
      .on('error', (e) =>{
        // window.alert('Something went wrong when pushing to the blockchain');
        let variant = "error"
        enqueueSnackbar('Something went wrong when pushing to the blockchain',{variant})
        collectionCtx.setNftIsLoading(false);  
      })      
    };

    formIsValid && mintNFT();
  };

  const nameClass = nameIsValid? "form-control" : "form-control is-invalid";
  const amountClass = amountIsValid? "form-control" : "form-control is-invalid";
  const descriptionClass = descriptionIsValid? "form-control" : "form-control is-invalid";
  const fileClass = fileIsValid? "form-control" : "form-control is-invalid";
  
  return(
    <div>
      
    
    <form onSubmit={submissionHandler}>
      <h1>Mint Card </h1>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <input
            type='text'
            className={`${nameClass} mb-1`}
            placeholder='Name...'
            value={enteredName}
            onChange={enteredNameHandler}
          />
        </div>
        <div className="col-md-4">
          <input
            type='text'
            className={`${amountClass} mb-1`}
            placeholder='Amount...'
            value={enteredAmount}
            onChange={enteredAmountHandler}
          />
        </div>
        <div className="col-md-4">
          <input
            type='file'
            className={`${fileClass} mb-1`}
            onChange={captureFile}
          />
        </div>
        <Box height={10} />
        <div className="col-md-12">
          <textarea
            // type="textare" 
            className={`${descriptionClass} mb-1`}
            placeholder='Description...'
            value={enteredDescription}
            rows={5}
            cols={5}
            onChange={enteredDescriptionHandler}
          />
        </div>

        <div className="col-md-6" style={{margin:20}}>
        <button type='submit' className='btn col-md-2  btn-success btn-lg text-white col-md-12 '>MINT</button>
        </div>
       
      </div>
      <Backdrop open={loadding}>
                   <CircularProgress  />
          </Backdrop>
    </form>

    </div>
  );
};

export default MintForm;