export const DECIMALS = (10**18);

export const ether = wei => wei / DECIMALS;

export const formatPrice = (price) => {
  const precision = 100000; // Use 2 decimal places

  price = ether(price);
  price = Math.round(price * precision) / precision;
   
  return price;
};

export const formatPriceEth = (price) => {
  const precision = 100; // Use 2 decimal places
  const ether2 = wei => wei / (10**8);
  price = ether2(price);
  price = Math.round(price * precision) / precision;
   
  return price;
};


export const formatUSD = (price) => {
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

