import { Alert, Backdrop, Button, CircularProgress, DialogTitle, Divider, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemText, Modal, Stack, Typography } from '@mui/material';
import * as React from 'react';
import {Link, useLocation} from "react-router-dom";
import { useContext, useState } from 'react';

import Web3Context from '../../store/web3-context';
import MarketplaceContext from '../../store/marketplace-context';
import web3 from '../../connection/web3';
import Web3Eth2 from '../../connection/Web3Eth2';
import getPOSClient from '../../connection/POSClient';
// import posClient from '../../connection/posClient';
import { formatPrice } from '../../helpers/utils';
import ModalConnect from '../materials/ModalConnect';
import ModalAccount from '../materials/ModalAccount';
import GuideBuy from '../materials/GuideBuy';

import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const getBalanceEthMatic = async (AC_address ) =>{
  const client =   await getPOSClient(AC_address);
  let erc20Token ;
  erc20Token = client.erc20(process.env.REACT_APP_ETH_ADDRESS_MATIC);
  if(AC_address) {
    return await erc20Token.getBalance(AC_address);
  }
  return -1;
  // console.log('balance On Matic',  balance);
}
const getBalanceEthOnETH = async ( AC_address) =>{
  return await Web3Eth2.eth.getBalance(AC_address)
}



const NavHeader = () => {

  const [fundsLoading, setFundsLoading] = useState(false);
  const [isEtherum, setEtherum] = useState(false);
  const web3Ctx = useContext(Web3Context);
  const marketplaceCtx = useContext(MarketplaceContext);
  const switchNetwork = ()=>{
    setLoadding(true)
    web3Ctx.addNetWork('matic').then(res => {
      setLoadding(false)
      handleClose();
      
    }, error =>{
      handleClose();
      setLoadding(false)
    });
    // handleClose();
  }
  let etherscanUrl;
  let alertNetwork = false;
  let isEtherNetwork = false;
  let ac_balance = 0;
  const style = {
    position: 'absolute',
    top: '50%',
    outline:'none',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 700,
    bgcolor: '#212529',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  const search = useLocation().search;
  const [parrms,setParams] = React.useState('')
  const [allowed,setAllowed] = React.useState(false);
  const [loading,setLoadding] = React.useState(false);
  const [switchMatic,setSwitchMatic] = React.useState(false);
  const [balanceETHOnETH,setBalanceETHOnETH] = React.useState(0)
  const [balanceETHOnMATIC,setBalanceETHOnMATIC] = React.useState(0)
  const [valueToken,setVauleToken] = React.useState(0.6);
  const { enqueueSnackbar } = useSnackbar();
  const init = async () =>{
    // console.log('isEtherNetwork22',isEtherNetwork);
    // console.log('isEtherNetwork',isEtherNetwork);
    if(!isEtherNetwork) return;
    if(web3Ctx) {
      const ethOnMAtic =   await getBalanceEthMatic(web3Ctx.account);
      // console.log('ethOnMAtic',ethOnMAtic);
      const ethOnEth =  await getBalanceEthOnETH(web3Ctx.account);
      // const ethOnEth =  await getBalanceEthOnETH("0x610207f22811b4660E7c159f5Bfa180d3cF7A993");
    
      setBalanceETHOnMATIC(formatPrice(ethOnMAtic));
      setBalanceETHOnETH(formatPrice(ethOnEth));
      // checkDeposited();
      if(valueToken > balanceETHOnETH || valueToken == 0) {
        setAllowed(false)
      }else{
        setAllowed(true);
      }
    }
   
  }


  // if(web3Ctx.account) {
  //   init();
  // }

  React.useEffect(async () => {
    const referer_code = new URLSearchParams(search).get('referer_code');
    if(referer_code){
      setParams('?referer_code='+referer_code);
    }
   await init();
    },[])


  if(marketplaceCtx.contract) {
      ac_balance = formatPrice(marketplaceCtx.balance); ;
    }

    
    if(process.env.REACT_APP_MODE == 'TEST'){
          if(web3Ctx.networkId === 5) {
            etherscanUrl = 'https://goerli.etherscan.io'
            isEtherNetwork  = true
          } else if(web3Ctx.networkId === 80001) {
            alertNetwork = true;
            etherscanUrl = 'https://mumbai.polygonscan.com/'
          } 
    }else{
        // mainnet
        if(web3Ctx.networkId === 137) {
          alertNetwork = true;
          etherscanUrl = 'https://  polygonscan.com/'
        } else if(web3Ctx.networkId === 1) {
          isEtherNetwork  = true
          etherscanUrl = 'https://etherscan.io'
        }
    }



  let ac_address = '';
  if(web3Ctx.account) {
    ac_address = web3Ctx.account.substring(0,5)+"..."+web3Ctx.account.substring(35,42);
  }

  const [open, setOpen] = React.useState(true);
  const enterToken = (e) =>{
    // console.log(e.target.value);
    if(e.target.value.length == 0) {
      setAllowed(false)
      setVauleToken('');
      return;
    }
    const valueEth =  parseFloat(e.target.value) ? parseFloat(e.target.value) : 0;
    // const valueEth =  e.target.value;
    if(valueEth > balanceETHOnETH || valueEth == 0) {
      setAllowed(false)
    }else{
      setAllowed(true);
    }
    setVauleToken(valueEth);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const max = () =>{
    setVauleToken(balanceETHOnETH);
    setAllowed(true);
  }
  // depositEther
const depositEtherToMatic = async () => {
    if(!allowed) {
      alert('not allowed');
      return;
    }
    setLoadding(true);
    const posClient =  await getPOSClient(web3Ctx.account);
    const priceSent =  web3.utils.toWei(valueToken.toString(), 'ether');
    const result = await posClient.depositEther(priceSent, web3Ctx.account );
    result.getTransactionHash().then( res => {
      setLoadding(true);
       result.getReceipt().then(res => {
        let variant = "success"
        enqueueSnackbar('Transfer success',{variant})
        setLoadding(false);
        // alert switch.
        setSwitchMatic(true);
       });
    },err => {
      let variant = "error"
      console.log('err',err);
      enqueueSnackbar('Error transfer',{variant})
      setLoadding(false);
    });
    
}
// const checkDeposited = async () =>{
//   const posClient   = await getPOSClient();
//   const isDeposited = await posClient.isDeposited('0x669ba7efa1d37f4d861cced1b2aefb41b0467421d198345aa0093dd1421f5f82');
// }
init();

  return(
    <div className=''>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark " style={{paddingLeft:"5%",paddingRight:"5%"}}>
        <a className="navbar-brand" href="#">
          <img src="https://milkywaygalaxy.io/wp-content/uploads/2021/10/logo.png" height="90px" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse  float-right" style={{paddingLeft:"8%", fontSize:"25px"}} id="navbarText">
          
            <ul className="navbar-nav  mr-auto">
                <li className="nav-item ">
                <Link className="nav-link" to={`/${parrms}`}>Market</Link>
                </li>
            </ul>
            <span className="navbar-text" style={{     marginLeft: 'auto', marginRight: '5%'}}>
            <Stack
                  direction={{ md: 'row' }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
            <GuideBuy></GuideBuy>
            {web3Ctx.account && 
            <ModalAccount></ModalAccount>
            }
            {!web3Ctx.account && 
              <ModalConnect></ModalConnect>
            }
              </Stack> 
            </span>
        </div>
        
    </nav>
    {!alertNetwork &&
    <Alert severity="warning" style={{justifyContent:'center'}}>Please connect polygon network! 
      {web3 &&
          <Button sx={{marginLeft:1}} variant='contained' color='info' size='small' onClick={switchNetwork}>Switch</Button> 
      }
      
    </Alert>
    }
    {isEtherNetwork &&
     <div>
      <BootstrapDialog
        onClose={handleClose}
        sx={{margin:'auto'}}
        fullWidth={true}
        open={true}
      >
        <BootstrapDialogTitle onClose={switchNetwork} >
          Convert Token
          {!switchMatic &&
          <Alert severity="info">You are on the <b style={{color:'red'}}>Etherum</b> network! 
              {/* {web3 &&
              <Button sx={{marginLeft:1}} variant='contained' color='info' size='small' onClick={switchNetwork}>Back Polygon</Button> 
              } */}
           </Alert>
           }
        </BootstrapDialogTitle>
        {!switchMatic &&
          <div>
            <DialogContent dividers>

                <Stack spacing={2} direction={'row'}  justifyContent="space-between"> <div style={{fontWeight:'bold'}}>From</div> <div>Balance :  <b>{balanceETHOnETH} </b> ETH </div> </Stack>
                <Box height={10}></Box>
                <Stack spacing={0} direction={'row'}  justifyContent="space-between"> 
                <div className='input-token-title'> 
                  <img width="20" src="https://storage.opensea.io/files/6f8e2979d428180222796ff4a33ab929.svg" />
                  <label style={{paddingLeft:'5px'}}>ETH</label>  
                </div>

                <InputBase value={valueToken} type='number' onChange={enterToken} className="input-token-value" endAdornment={
                  <Button sx={{fontWeight:'bold'}} onClick={max} > Max </Button>
                } />
                
                </Stack>

                <Box height={90} sx={{display:'flex', justifyContent:'center',alignItems:'center'}}>
                </Box>
                <Stack spacing={2} direction={'row'}  justifyContent="space-between"> <div style={{fontWeight:'bold'}}>To</div> <div>Balance : <b> {balanceETHOnMATIC} </b>ETH</div> </Stack>
                <Box height={10}></Box>
                <Stack spacing={0} direction={'row'}  justifyContent="space-between"> 
                  <div className='input-token-title'> 
                    <img width="20" src="https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg" />
                    <label style={{paddingLeft:'5px'}}>ETH</label>
                  </div>
                  <InputBase disabled value={valueToken} className="input-token-value" />
                </Stack>
            </DialogContent>
            <DialogActions style={{justifyContent:'center',padding:'20px'}} >
              <Button  size="large" disabled={!allowed} color="info" sx={{fontWeight:'bold'}} onClick={depositEtherToMatic}  variant='contained' >
                Convert Tokens
              </Button>

            </DialogActions>
          </div>
          }
            {switchMatic &&
            <div>

          <DialogContent dividers>
            <Box height={10}></Box>
              <div className='box-success'>
                <Stack spacing={2} direction={'row'}   justifyContent="flex-start" alignItems="center">
                  <CheckCircleOutlineIcon sx={{fontSize:60, color:'#36c180'}}></CheckCircleOutlineIcon>
                <div>
                Convert was successful. 
                Please check your wallet in 1-5 minutes.
                </div>
             
                </Stack>
                
              </div>
          </DialogContent>
          <DialogActions style={{justifyContent:'center',padding:'20px'}} >
          <Button  size="large"  color="success" sx={{fontWeight:'bold'}} onClick={switchNetwork}  variant='contained' >
            Back to Polygon
          </Button>
          </DialogActions>
          </div>
            }
        <Backdrop open={loading}>
                  <CircularProgress  />
        </Backdrop>
      </BootstrapDialog>
   </div>

    }
    </div>
  );
};

export default NavHeader;