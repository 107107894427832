import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';

import { Link, useLocation } from 'react-router-dom';
import { formatUSD } from '../../helpers/utils';

export default function OutlinedCard(props) {
  const search = useLocation().search;
  const [parrms,setParams] = React.useState('')
  React.useEffect(() => {
    const referer_code = new URLSearchParams(search).get('referer_code');
    if(referer_code){
      setParams('?referer_code='+referer_code);
    }
    }, [])
  return (
    <Box  >
      {/* <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_v6zesxag.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop controls autoplay></lottie-player> */}
      <Card variant="outlined" sx={{borderRadius:2,border:1,borderColor:"rgb(30, 73, 118)",  background:'rgb(0, 30, 60)'}}>
    
      <Link className="nav-link" to={`/card/${props.dataNFT.index}${parrms}`}>
        <CardContent>
            <Typography sx={{ fontSize: 17,fontWeight:800,textAlign:"left", paddingBottom:"10px",color:"white"  }}  gutterBottom>
            {/* #{props.dataNFT.idFake}  */}
            </Typography>
            <CardMedia
                component="img"
                height="300"
                // width="30"
                // image={`https://ipfs.infura.io/ipfs/${props.dataNFT.img}`}
                image={`${props.dataNFT.img}`}
                alt=""
            />
            <Typography  sx={{ fontSize: 22, fontWeight: 800, textAlign:'center',padding:"10px" }} color="text.primary" gutterBottom>
              {props.dataNFT.title}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Available:  {props.dataNFT.available}
            </Typography>
            <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
            Price:  {props.dataNFT.price} <img width="16" src='https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg' />
            </Typography>
            <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
              {formatUSD(props.dataNFT.priceUSD)}
            </Typography>
           
           
         
          </CardContent>
          </Link>
       
      </Card>
    </Box>
  );
}