import * as React from 'react';
import NFTCollection from '../Content/NFTCollection/NFTCollection';
import NFTCollectionForSell from '../Content/NFTCollection/NFTCollectionForSell';
import { useContext  } from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import NavHeader from '../Layout/NavHeader';
import { Typography } from '@mui/material';
import BackButton from '../materials/BackButton'
import { Box } from '@mui/system';


const YourCard = () => {

  const marketplaceCtx = useContext(MarketplaceContext);
  return(
    <main role="main" className="col-lg-12 mb-2">
        <NavHeader></NavHeader>
        <div className="container">
        <Box height={20}></Box>
        <BackButton></BackButton>
        <Box height={30}></Box>
        <Typography variant="h2" gutterBottom>
        My card
        </Typography>
        {/* { <NFTCollectionForSell />} */}
        { <NFTCollection />}
        </div>
    </main>
  );
};

export default YourCard;