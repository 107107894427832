import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';



export default function ModalStart() {
  const [open, setOpen] = React.useState(true);
  let started = sessionStorage.getItem('startEd');
  React.useEffect(() => {
    if(started == 1) {
      setOpen(false);
    }
  }, [])
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('startEd','1')
  };
  const moreBonus = () => {
    window.open(window.location.href+"MWG_Pioneer.pdf", '_blank');
    handleClose();
  }


  return (
    <div >
      <Dialog className="dialog_start" open={open} 
      sx={{
        backgroundImage:'linear-gradient(rgb(255 255 255 / 0%), rgba(255, 255, 255, 0.16))',
        '& .MuiDialog-paperWidthSm' :{
          maxWidth: '800px',
          backgroundImage:'none',
          backgroundColor:'#12121200'
        }
      }} 
      fullWidth={true} 
      //  onClose={handleClose}
        >
        <DialogContent  
        sx={{ 
        minHeight: '540px', 
        backgroundImage:"url(imgs/modalStart.png)",
        backgroundSize:"100% 100%" ,color:'black'}}  >
        <img className='btn_1' onClick={moreBonus} src='imgs/btn1.png' />
        <img className='btn_2' onClick={handleClose} src='imgs/btn2.png' />
        </DialogContent>
      </Dialog>
    </div>
  );
}