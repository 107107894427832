import { useReducer } from 'react';

import VNDContext from './vnd-context';

const defaultvndState = {
  contract: null,
  balance:null
};

const vndReducer = (state, action) => {
  if(action.type === 'CONTRACT') {    
    return {
      contract: action.contract,
      balance: state.balance,
    };
  } 
  if(action.type === 'LOADBALANCE') {    
    return {
      contract: state.contract,
      balance: state.balance,
    };
  } 
  return defaultvndState;
};

const VNDProvider = props => {
  const [vndState, dispatchCollectionAction] = useReducer(vndReducer, defaultvndState);

  const loadContractHandler = (web3, VND, deployedNetwork) => {
    const contract = new web3.eth.Contract(VND.abi, process.env.REACT_APP_ETH_ADDRESS_MATIC);
    // const contract = new web3.eth.Contract(VND.abi, '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619') ;
    dispatchCollectionAction({type: 'CONTRACT', contract: contract}); 
    return contract;
  };

  const loadBalanceOfHandler = async (contract, account) => {
    const balance = await contract.methods.balanceOf(account).call();
    dispatchCollectionAction({type: 'LOADBALANCE', balance: balance});
    return balance;
  };

  const vndContext = {
    contract: vndState.contract,
    balance: vndState.balance,
    loadBalance: loadBalanceOfHandler,
    loadContract: loadContractHandler,
  };
 
  return (
    <VNDContext.Provider value={vndContext}>
      {props.children}
    </VNDContext.Provider>
  );
};

export default VNDProvider;