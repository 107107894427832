import React from 'react';

const ServerDataContext = React.createContext({
  nftSelling:[],
  ethPirce:0,
  loadNFTselling: () => {},
  loadPrice: () => {},

}); 

export default ServerDataContext;