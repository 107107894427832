import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';
import { DialogActions, IconButton, Link, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
export default function ModalGuideBuy() {
  const [open, setOpen] = React.useState(true);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div >
        <DialogContent sx={{background:'white',color:'black'}}>
          <DialogContentText sx={{padding:'10px'}}>
              <Stack spacing={1} direction={'column'}>
              <Typography  variant="h6" color={'black'} >
                Step 1: Link to your Metamask wallet
                </Typography>
                <Typography  variant="h6" color={'black'} >
                Step 2: Check Gas Charge
                </Typography>
                <Typography  variant="h6" color={'black'} >
                Step 3: Unlock Ethereum if first time buying
                </Typography>  
                {/* <Box height={2} ></Box>
                <Typography  variant="p"   >
                <Link href="#" sx={{color:'red', fontWeight:'bold'}} ><OndemandVideoIcon></OndemandVideoIcon> Purchase Guideline</Link>
                </Typography>                 */}
              </Stack>
          </DialogContentText>
        </DialogContent>
       
      {/* </Dialog>  */}
    </div>
  );
}