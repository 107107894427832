import Web3 from 'web3';
// import { formatPriceEth } from '../helpers/utils';
// const Web3Matic = new Web3("https://rpc-mainnet.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e");
// const Web3Matic = new Web3("https://rpc-mumbai.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e");
let Web3Matic;
if(process.env.REACT_APP_MODE == 'TEST'){
     Web3Matic = new Web3("https://rpc-mumbai.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e");
}else{
     Web3Matic = new Web3("https://rpc-mainnet.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e");
}

export default Web3Matic;

