import * as React from 'react';
import ADNFTCollection from '../Content/NFTCollection/ADNFTCollection';
import NFTCollectionForSell from '../Content/NFTCollection/NFTCollectionForSell';
import { useContext  } from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import NavHeader from '../Layout/NavHeader';


const ADYourCard = () => {

 
  return(
    <main role="main" className="col-lg-12  mb-2">
        { <ADNFTCollection />}
    </main>
  );
};

export default ADYourCard;