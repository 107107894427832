import React, { useContext, useEffect, useState } from 'react';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import web3 from './connection/web3';

import Main from './components/Content/Main';
import Web3Context from './store/web3-context';
import CollectionContext from './store/collection-context';
import MarketplaceContext from './store/marketplace-context'
import VNDContext from './store/vnd-context'
import NFTCollection from './abis/NFTCollection.json';
import NFTMarketplace from './abis/NFTMarketplace.json';
import VND from './abis/VND.json';
import { SnackbarProvider } from 'notistack';

import {
  BrowserRouter as Router,

} from "react-router-dom";

import ServerDataContext from './store/server-context';



const App = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const serverDataPro = useContext(ServerDataContext);
  const vndCtx = useContext(VNDContext);
   
  const [loading, setloading] = useState(false);
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
    components: {
      // Name of the component
      MuiCard: {
        variants: [
          {
            props: { variant: 'outlinedp' },
            style: {
              backgoundColor:'rgb(0, 30, 60) !important',
              border: '1px solid rgb(30, 73, 118)',
            },
          },
        ],
      },
    },
    typography: {
      fontFamily: [
        'Quicksand',
        'sans-serif'
      ].join(','),
    },
  });

  useEffect( async() => {
   
    const loadData = async ()=>{
      if(serverDataPro) {
        serverDataPro.loadPrice();
        serverDataPro.loadNFTselling();
      }
     
    }
    loadData();
    // Check if the user has Metamask active
    if(!web3) {
      return;
    }
   
    // Function to fetch all the blockchain data
    const loadBlockchainData = async() => {
      setloading(true)
      // Request accounts acccess if needed
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });  
      } catch(error) {
        setloading(false);
        console.error(error);
      }
      
      // Load account
      const account = await web3Ctx.loadAccount(web3);

      // Load Network ID
      const networkId = await web3Ctx.loadNetworkId(web3);

      // Load Contracts      
      const nftDeployedNetwork = NFTCollection.networks[networkId];
      const nftContract = collectionCtx.loadContract(web3, NFTCollection, nftDeployedNetwork);

      const mktDeployedNetwork = NFTMarketplace.networks[networkId];
      const mktContract = marketplaceCtx.loadContract(web3, NFTMarketplace, mktDeployedNetwork);
     
      const vndDeployedNetwork = VND.networks[networkId];
      const vndContract = vndCtx.loadContract(web3, VND, vndDeployedNetwork);
      // console.log('vndContract',vndContract);

      let offersALl;
      let offerCount;
      if(mktContract) {
        // marketplaceCtx.contract.methods.balanceERC20('0xc94E3be02278f2ebE8E92592793279174D65e828').call().then(res =>{
        //   console.log('ressss',res);
        // });

        // Load offer count
        await marketplaceCtx.loadOwner(mktContract);
         offerCount = await marketplaceCtx.loadOfferCount(mktContract);
        // Load offers
        offersALl = await marketplaceCtx.loadOffers(mktContract, offerCount); 
        // Load User Funds
        account && marketplaceCtx.loadUserFunds(mktContract, account);
        account && marketplaceCtx.loadUserBalance(mktContract, account);
        // Event OfferFilled subscription 
        mktContract.events.OfferFilled()
        .on('data', async (event) => {
          serverDataPro.loadNFTselling();
          marketplaceCtx.setMktIsLoading(false);
        })
        .on('error', (error) => {
          console.log(error);
        });
      } 
      if(nftContract) {        
        // Load total Supply
        // const totalSupply = await collectionCtx.loadTotalSupply(nftContract);
        const nftOfAccount = await collectionCtx.loadNFTofAccount(nftContract, account);
        collectionCtx.loadCollection(nftContract, nftOfAccount, account);    

        // Event subscription
        // nftContract.events.Transfer()
        // .on('data', (event) => {
        //   collectionCtx.updateCollection(nftContract, event.returnValues.tokenId, event.returnValues.to);
        //   collectionCtx.setNftIsLoading(false);
        // })
        // .on('error', (error) => {
        //   console.log(error);
        // });

          
   
        
      } 

      if(mktContract && nftContract){
             // Event offerCancelled subscription 
             mktContract.events.OfferCancelled()
             .on('data', async (event) => {
              const offerCount = await marketplaceCtx.loadOfferCount(mktContract);
              offersALl = await marketplaceCtx.loadOffers(mktContract, offerCount); 
               marketplaceCtx.updateOffer(event.returnValues.offerId);
               serverDataPro.loadNFTselling();
               marketplaceCtx.setMktIsLoading(false);
             })
             .on('error', (error) => {
               console.log(error);
             });
             
              // Event Offer subscription 
           mktContract.events.Offer()
           .on('data', async (event) => {
            const nftOfAccount = await collectionCtx.loadNFTofAccount(nftContract, account);
            collectionCtx.loadCollection(nftContract, nftOfAccount, account);  
            serverDataPro.loadNFTselling();
            marketplaceCtx.setMktIsLoading(false);
           })
           .on('error', (error) => {
             console.log(error);
           });

           mktContract.events.OfferFilling()
           .on('data', async (event) =>  {
             const nftOfAccount = await collectionCtx.loadNFTofAccount(nftContract, account);
             collectionCtx.loadCollection(nftContract, nftOfAccount, account);  
             serverDataPro.loadNFTselling();
             marketplaceCtx.setMktIsLoading(false);
           })
           .on('error', (error) => {
             console.log(error);
           });

          
      }

      if(vndContract) {
        // Load Balance
        account && vndCtx.loadBalance(vndContract, account);
      }

      collectionCtx.setNftIsLoading(false);
      marketplaceCtx.setMktIsLoading(false);

    
    };
   
    await loadBlockchainData();
    setloading(false);
      // Metamask Event Subscription - Account changed
      window.ethereum.on('accountsChanged', async (accounts) => {
        // web3Ctx.loadAccount(web3);
        // accounts[0] && marketplaceCtx.loadUserFunds(mktContract, accounts[0]);
        // window.location.reload();
        await loadBlockchainData();
        setloading(false);
      });

      // Metamask Event Subscription - Network changed
      window.ethereum.on('chainChanged', async (chainId) => {
        // window.location.reload();
        await loadBlockchainData();
        setloading(false);
      });
  }, []);

  const showNavbar = web3 && collectionCtx.contract && marketplaceCtx.contract;
  const showContent = web3 && collectionCtx.contract && marketplaceCtx.contract && web3Ctx.account;
  
 
  return(
    <SnackbarProvider maxSnack={3} 
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
  }} >
      <React.Fragment>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
        <Router>
          {<Main />}
          {/* <Backdrop open={loading} >
                        <CircularProgress  />
          </Backdrop> */}
        </Router>
        </ThemeProvider>
      </React.Fragment>
    </SnackbarProvider>
  );



};

export default App;