import Web3 from 'web3';
// import { formatPriceEth } from '../helpers/utils';


// const Web3Eth2 = new Web3("https://mainnet.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd");

let Web3Eth2 ;
if(process.env.REACT_APP_MODE == 'TEST'){
     Web3Eth2 = new Web3("https://goerli.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd");
}else{
     Web3Eth2 = new Web3("https://mainnet.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd");
}


export default Web3Eth2;

