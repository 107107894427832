import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ModalConnect from '../materials/ModalConnect';
import Web3Context from '../../store/web3-context';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import MarketplaceContext from '../../store/marketplace-context';
import { useNavigate } from 'react-router-dom';


const theme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const web3Ctx = React.useContext(Web3Context);
  const martketCtx = React.useContext(MarketplaceContext);
  const { enqueueSnackbar } = useSnackbar();
  const connectWalletHandler = async() => {
    try {
      // Request account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      if(web3Ctx.account && martketCtx.owner){
        if(web3Ctx.account == martketCtx.owner){
          let variant = "success";
        enqueueSnackbar("Success ", { variant });
        navigate('/ad-dashboard');
        }else{
          let variant = "error";
          enqueueSnackbar("You do not have access!!", { variant });
        }
      }
    } catch(error) {
      let variant = "warning";
      enqueueSnackbar("Can not connect wallet, please try again. ", { variant });
    }
  };

  return (
    <ThemeProvider theme="dark">
      <Container component="main" >
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:'center',
            height:'100vh'
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          </Avatar> */}
          <img src="https://milkywaygalaxy.io/wp-content/uploads/2021/10/logo.png" height='200px' />
          <Typography component="h1" variant="h3">
            Login NFT Market
          </Typography>
          <Box height={30}></Box>
          <Button size='large' onClick={connectWalletHandler}  variant="outlined">Connect Wallet</Button>
        </Box>
       
      </Container>
    </ThemeProvider>
  );
}