import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';


export default function Step3() {

  return (

      <div>
            <div className="row p-1" >
              <Box height={10}></Box>
              <div className="col-md-12">
                <div >
                    <Stack spacing={1} direction={'row'}  alignItems={{md:'center'}}>
                      <div style={{fontSize:'1.2em'}}> If this is your first time trading at Another Universe Market, please unblock Ethereum so the transaction can proceed.</div>
                    </Stack>
                </div>
              </div>
            </div>
      </div>
   


  );
}