
import { POSClient,use } from "@maticnetwork/maticjs"
import { Web3ClientPlugin } from '@maticnetwork/maticjs-web3'
import React from "react";
import web3 from "./web3";
// import HDWalletProvider from "@truffle/hdwallet-provider"
// import Web3Context from './store/web3-context';
// install web3 plugin
use(Web3ClientPlugin);
const provider = window.ethereum ? window.ethereum : null; 
// console.log('web3',web3);

//main
// const parentProvider = 'https://rpc-mainnet.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e'
// const maticProvider = ''
// test
// const parentProvider = 'https://goerli.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd'
// const maticProvider = 'https://rpc-mumbai.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e'

//mainnet
// const parentProvider = 'https://mainnet.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd'
// const maticProvider = 'https://rpc-mainnet.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e'
let parentProvider;
let maticProvider;
let network;
let version;
let from;

// parentProvider = web3
if(process.env.REACT_APP_MODE == 'TEST'){
   parentProvider = 'https://goerli.infura.io/v3/e67bb9f88bbe4f999c8a2397d969f1dd'
   maticProvider = 'https://rpc-mumbai.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e'
   network = 'testnet';
   version = 'mumbai';
  //  from = web3Ctx.account;/
}else{
  
 maticProvider = 'https://rpc-mainnet.maticvigil.com/v1/ad16e16175b65b9cd35ea1901ece28b9f85ecd6e'
   network = 'mainnet';
   version = 'v1';
   from = '0xBddFCD99830c3db06389D13be83aFd42012E05AC';
}

const getPOSClient = async (account) => {
  const posClient = new POSClient();
  return  posClient.init({
      network: network,
      version: version,
      
      parent: {
        provider: web3,
      
        defaultConfig: {
          from : account
        }
      },
      child: {
        provider: maticProvider,
        defaultConfig: {
          from :account
        }
      }
    });

}


export default getPOSClient