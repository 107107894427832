import { useContext, useRef, createRef } from 'react';
import * as React from 'react';
import web3 from '../../../connection/web3';
import web3Eth from '../../../connection/web3Eth';
import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import VNDContext from '../../../store/vnd-context';
import MarketplaceContext from '../../../store/marketplace-context';
import { formatPrice, formatPriceEth } from '../../../helpers/utils';
import {  useEffect, useState } from 'react';
import {  Grid, Pagination, Typography } from '@mui/material';
import OutlinedCard from '../../materials/OutlinedCard';
import { Box } from '@mui/system';
import Spinner from '../../Layout/Spinner';
import callApi from '../../../api';
import ServerDataContext from '../../../store/server-context';
import ModalStart from '../../materials/ModalStart';

const Market = () => {
  const web3Ctx = useContext(Web3Context);
  const serverProvider = useContext(ServerDataContext);
 
  const marketplaceCtx = useContext(MarketplaceContext);
  const VNDContextCtx = useContext(VNDContext);
  const pageSize = 12;
  const [page,  setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(pageSize);
  
  useEffect(()=>{
    
    if(serverProvider.nftSelling.length != 0) {
      const p = Math.ceil(serverProvider.nftSelling.length/pageSize)
      setPageTotal(p); 
    }
    document.getElementById("body-main").style.overflow = "auto";
  })
  const handleChange = async (event, value) => {
    await setPage(value);
    const end_ =  value * pageSize;
    const start_ =  value * pageSize - pageSize;
    await setStart(start_);
    await setEnd(end_);
    
  };

  return(
         <Box sx={{ flexGrow: 1 }}>
             <Box height="60px"></Box>
             <Typography variant="h3" sx={{fontWeight:'800'}} gutterBottom component="div">
               WELCOME TO ANOTHER UNIVERSE NFT MARKETPLACE
            </Typography>
             
             <Typography variant="h5" gutterBottom component="div">
             The world of excellent NFT items,  purchase and be our PNR.
            </Typography>
             
             <Box height="70px"></Box>
            <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                {serverProvider.nftSelling !=0 &&
                 serverProvider.nftSelling.map((NFT, key) => {
                    if(key < start || key >= end) return false;
                    const index = marketplaceCtx.offers ? NFT.offer.offerId : -1;
                    const price =  formatPrice(NFT.offer.price).toFixed(2);
                    NFT.price = price ? price : 10;
                    NFT.priceUSD = serverProvider.ethPirce * price;
                    NFT.index = index;
                    return(
                        <Grid item xs={4} sm={4} md={3} key={index}>
                            <OutlinedCard dataNFT={NFT} ></OutlinedCard>
                        </Grid>
                    );
              })}
        </Grid>
        <Box height="30px"></Box>
        <Pagination size="large" count={pageTotal} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
        <Box height="80px"></Box>
        <ModalStart></ModalStart>
    </Box>                     
    
  );
};

export default Market;