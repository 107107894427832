import { useContext, useRef, createRef,useState,useEffect } from 'react';

import web3 from '../../../connection/web3';
import Web3Context from '../../../store/web3-context';
import CollectionContext from '../../../store/collection-context';
import VNDContext from '../../../store/vnd-context';
import MarketplaceContext from '../../../store/marketplace-context';
import { formatPrice } from '../../../helpers/utils';
import eth from '../../../img/eth.png';
import ADMyCard from '../../materials/ADMyCard';
import { Grid ,Box, Backdrop, CircularProgress} from '@mui/material';

const NFTCollection = () => {
  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const VNDContextCtx = useContext(VNDContext);
  const [loadding, setLoadding] = useState(false)
  let balanceOf = 0;
  let allowance = 0;

  VNDContextCtx.contract.methods
  .allowance(web3Ctx.account, marketplaceCtx.contract.options.address).call().then(res => allowance = res)

  
  useEffect(() => {
    if(!collectionCtx.collection) {
      setLoadding(true);
    }else{
      setLoadding(false);
    }
      
  // console.log('VNDContextCtx.contract',VNDContextCtx);
  }, [])
  
  return(
    <div className="">
       <h1 style={{margin:20}}>My card</h1>
       <Grid container md={12} spacing={{ xs: 2, md: 2 }} >
      {collectionCtx.collection &&
      collectionCtx.collection.map((NFT, key) => {
        // console.log(web3Ctx.account);
        if(NFT.owner != web3Ctx.account) return false;
        const index = marketplaceCtx.offers ? marketplaceCtx.offers.findIndex(offer => offer.id === NFT.id) : -1;
        const owner = index === -1 ? NFT.owner : marketplaceCtx.offers[index].user;
        const price = index !== -1 ? formatPrice(marketplaceCtx.offers[index].price).toFixed(2) : null;
        NFT.key=key;
        NFT.price=price;
        NFT.owner=owner;
        // balanceOf
        return(
          <Grid item xs={12}  sm={3} key={index}>
            <ADMyCard dataNFT={NFT}>
            </ADMyCard>
          </Grid>
        );
      })}
      </Grid>
      <Box sx={{ height: 100,}}></Box>

      <Backdrop open={(!collectionCtx.collection && collectionCtx.collection != [])}>
                   <CircularProgress  />
          </Backdrop>
     </div>
  );
};

export default NFTCollection;