import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import HelpIcon from '@mui/icons-material/Help';
import { AppBar, Toolbar } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
export default function GuideBuy() {
  const [state, setState] = React.useState({
    bottom: false,
    top: false,
    left: false,
  });
  const anchor = 'top';
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
      document.getElementById("body-main").style.overflow = "auto";
      document.getElementById("body-main").style.padding = "auto";
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation" 
      onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div style={{height:'100vh'}}>
      <AppBar position="fixed" >
        <Toolbar>
          <Button color="inherit">Close</Button>
        </Toolbar>
      </AppBar>
      <img src='/imgs/1.jpg' width= '100%' />
      <img src='/imgs/2.jpg' width= '100%' />
      <img src='/imgs/3.jpg' width= '100%' />
      <img src='/imgs/4.jpg' width= '100%' />
      <img src='/imgs/5.jpg' width= '100%' />
      <img src='/imgs/6.jpg' width= '100%' />
      <img src='/imgs/7.jpg' width= '100%' />
      </div>
     

    </Box>
  );

  return (
    <div>
      {
        <React.Fragment key={anchor}>
          <Button  onClick={toggleDrawer(anchor, true)}><HelpIcon sx={{fontSize:36}}></HelpIcon></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      }
    </div>
  );
}
