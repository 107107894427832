import axios from 'axios';
//mock API
let API_URL = process.env.REACT_APP_SERVER_API_URL;

export default function callApi(aciont, method = 'GET', body) {
    return axios({
        method,
        headers: {
            'Cockpit-Token': `${process.env.REACT_APP_SERVER_API_KEY}`,
            'Content-Type': 'application/json'
        },
        url: `${API_URL}/api/collections/${aciont}`,
        data: body
    }).catch(err => {
        console.log(err);
    });
}

export  function checkCodeRef(codeRef = 'astA457A457A') {
    var bodyFormData = new FormData();
    bodyFormData.append('referer_code', codeRef);
    return axios({
        method:'POST',
        url: `https://milkywaygalaxy.io/wp-content/plugins/pm_buy_token/api/referer.php`,
        data: bodyFormData
    }).catch(err => {
        return 0
    }).then(res =>{
        return res.data.rowCount
    });
}

export  function addTransactionServerB(email = '',txid,referer_code,customer_wallet_address,eth_price,exchange_eth_rate,nft_wallet_address,
token,ETH,testing) {
    var bodyFormData = new FormData();
    bodyFormData.append('market', "nftmarket.milkywaygalaxy.io");
    bodyFormData.append('email', email);
    bodyFormData.append('txid', txid);
    bodyFormData.append('referer_code', referer_code);
    bodyFormData.append('customer_wallet_address', customer_wallet_address);
    bodyFormData.append('eth_price', eth_price);
    bodyFormData.append('exchange_eth_rate', exchange_eth_rate);
    bodyFormData.append('nft_wallet_address', nft_wallet_address);
    bodyFormData.append('token', token);
    bodyFormData.append('ETH', ETH);
    bodyFormData.append('testing', testing);
    return axios({
        method:'POST',
        url: process.env.REACT_APP_SERVER_B,
        data: bodyFormData
    }).catch(err => {
        return 0
    })
}