import * as React from 'react';

import { useContext  } from 'react';
import {  Spinner } from 'react-bootstrap';

import CollectionContext from '../../store/collection-context';
import MintForm from '../Content/MintNFT/MintForm';
import NavHeader from '../Layout/NavHeader';

const Mint = () => {

    const collectionCtx = useContext(CollectionContext);
  return(
    <main role="main" className="col-lg-12 justify-content-center text-center mb-2">
      <NavHeader></NavHeader>
      <div className='container'>
             {!collectionCtx.nftIsLoading && <MintForm />}
            {collectionCtx.nftIsLoading && <Spinner />}
            </div>
    </main>
  );
};

export default Mint;