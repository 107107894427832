import { ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {

    const nav = useNavigate()

  return (
    <Button variant="text" size="large" onClick={()=>{nav('/')}} startIcon={<ArrowBackIos />}>Back</Button>
  );
}