import * as React from 'react';

import { useContext  } from 'react';
import {  Spinner } from 'react-bootstrap';
import CollectionContext from '../../store/collection-context';
import MintForm from '../Content/MintNFT/MintForm';
import NavLeft from '../Layout/NavLeft';
import { Box } from '@mui/system';
import { Backdrop, Chip, CircularProgress, Grid, TextField } from '@mui/material';
import { Alert, Button } from '@mui/material';
import MarketplaceContext from '../../store/marketplace-context';
import Web3Context from '../../store/web3-context';
import { useNavigate } from 'react-router-dom';
import { CheckOwner } from '../../helpers/lib';
import DoneIcon from '@mui/icons-material/Done';
import { useSnackbar } from 'notistack';

const ADSetting = () => {
    const { enqueueSnackbar } = useSnackbar();
    const collectionCtx = useContext(CollectionContext);
    const [ownered, setOnwnered] = React.useState(false);  
    const [loadding, setLoadding] = React.useState(false);  
    const [receove_address, addressReceiveWETH] = React.useState("");
    const [_addressWETH, set_addressWETH] = React.useState("");
    const [_enterAddressReceive, setEnterAddressReceive] = React.useState("");
    const marketplaceCtx = useContext(MarketplaceContext);
    const collecttionCtx = useContext(CollectionContext);
    const web3Ctx =  useContext(Web3Context);
    const navigate = useNavigate();

    if(marketplaceCtx.owner && web3Ctx.account) {
      if(!CheckOwner(marketplaceCtx,web3Ctx)){
        navigate('/login');
      }
    }

    const setValueAddressReceice = (event) => {
        setEnterAddressReceive(event.target.value);
    };
    const setAddressReceiveHandle = () =>{
      if(_enterAddressReceive.length != 42){
        let variant = "error"
        enqueueSnackbar('Invalid address Receive',{variant})
        return;
      }
      marketplaceCtx.contract.methods.setAddressReceive(_enterAddressReceive).send({from:web3Ctx.account})
      .on('transactionHash', (hash) => {
        setLoadding(true);
      })
      .on('receipt', (receipt) => {
        let variant = "success"
        setLoadding(false);
        enqueueSnackbar('Success',{variant})
        console.log('receipt',receipt);
        // addressReceiveWETH(receipt.events.returnValues.newAdressReceive);
      })
      .on('error', (error) => {
        setLoadding(false);
        window.alert('Something went wrong when pushing to the blockchain');
      });
    }

    React.useEffect(() => {
      if(marketplaceCtx.owner && web3Ctx.account) {
        setOnwnered(CheckOwner(marketplaceCtx,web3Ctx));
        if(!CheckOwner(marketplaceCtx,web3Ctx)){
          navigate('/login');
        }
        marketplaceCtx.contract.methods._addressReceiveWETH().call().then(res => {
          addressReceiveWETH(res);
        })
        marketplaceCtx.contract.methods._addressWETH().call().then(res => {
          set_addressWETH(res);
        })
        // collecttionCtx.contract.methods._addressWETH().call().then(res => {
        //   set_addressWETH(res);
        // })
      }
    })
   
  return(
   
    <div style={{height:'100vh'}}>
    {ownered &&
    <Box sx={{ display: 'flex' }} >
         <NavLeft></NavLeft>
          <div className='container'  component="main" >
            <Box height="80px"></Box>
            <h2>Setting</h2>
            <Box height="30px"></Box>
            <Grid container xs={12} spacing={{xs:3,md:3}} md={12} style={{borderColor: 'rgb(30, 73, 118)', background: '#28456e7d'}} >
              <Grid item xs={12} >
                <Alert severity="info" variant="filled">Update the setting information will have a big impact, please be careful!</Alert>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Box height="10px"></Box>
                  Receive ETH 
                  <Box height="5px"></Box>
                  <Chip label={receove_address} variant="" />
                  <Box height="10px"></Box>
                  <TextField onChange={setValueAddressReceice} value={_enterAddressReceive} size="small" sx={{width:400,marginRight:2}} label="Address recevice" variant="outlined" />
                  <Button onClick={setAddressReceiveHandle} variant='outlined' > apply </Button>
                 
                  {/* <Box height="10px"></Box>
                  <TextField   size="small" sx={{width:400,marginRight:2}} label="address ERC20" variant="outlined" />
                  <Button variant='outlined' > apply </Button> */}
                  <Box height="40px"></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Box height="10px"></Box>
                    Address NFT
                  <Box height="5px"></Box>
                   <Chip label="0x3B5883eA952947A4FDD05Df1F51d395a9043ED72" variant=""  />
                  <Box height="15px"></Box>
                    Owner
                  <Box height="5px"></Box>
                   <Chip label={web3Ctx.account} variant=""  />
                  <Box height="15px"></Box>
                  {/* <Box height="30px"></Box> */}
                  ERC20 for PRICE 
                  <Box height="5px"></Box>
                  <Chip label={_addressWETH} variant="" />
                  <Box height="30px"></Box> 
              </Grid>
            </Grid>
          </div>
          <Backdrop open={loadding}>
                   <CircularProgress  />
          </Backdrop>
    </Box>}
    </div>
  );
};

export default ADSetting;