import { useReducer } from 'react';
import callApi from '../api';
import { formatPrice } from '../helpers/utils';

import CollectionContext from './collection-context';

const defaultCollectionState = {
  contract: null,
  totalSupply: null,
  collection: null,
  nftSelling: [],
  nftOfAccount: null,
  nftIsLoading: true
};
const listSelling = () => {
  return  callApi('get/nft_selling');
  }

const collectionReducer = (state, action) => {
  if(action.type === 'CONTRACT') {    
    return {
      contract: action.contract,
      totalSupply: state.totalSupply,
      collection: state.collection,
      nftSelling: state.nftSelling,
      ftOfAccount: state.nftOfAccount,
      nftIsLoading: state.nftIsLoading
    };
  } 
  
  if(action.type === 'LOADSUPPLY') {
    return {
      contract: state.contract,
      totalSupply: action.totalSupply,
      nftSelling: state.nftSelling,
      nftOfAccount: state.nftOfAccount,
      collection: state.collection,
      nftIsLoading: state.nftIsLoading
    };
  }
  if(action.type === 'LOADNFTOFACCOUNT') {
    return {
      contract: state.contract,
      nftOfAccount: action.nftOfAccount,
      collection: state.collection,
      nftSelling: state.nftSelling,
      nftIsLoading: state.nftIsLoading
    };
  }

  if(action.type === 'LOADCOLLECTION') { 
    
    return {
      contract: state.contract,
      totalSupply: state.totalSupply,
      collection: action.collection,
      ftOfAccount: state.nftOfAccount,
      nftSelling: state.nftSelling,
      nftIsLoading: state.nftIsLoading
    };
  }

  if(action.type === 'LOADSELL') { 
   
    return {
      contract: state.contract,
      totalSupply: state.totalSupply,
      nftSelling: action.nftSelling,
      ftOfAccount: state.nftOfAccount,
      collection: state.collection,
      nftIsLoading: state.nftIsLoading
    };
  }

  if(action.type === 'UPDATECOLLECTION') {    
    const index = state.collection.findIndex(NFT => NFT.id === parseInt(action.NFT.id));
    let collection = [];

    if(index === -1) {
      collection = [action.NFT, ...state.collection];
    } else {
      collection = [...state.collection];
    }    

    return {
      contract: state.contract,
      totalSupply: state.totalSupply,
      nftSelling: state.nftSelling,
      collection: collection,
      ftOfAccount: state.nftOfAccount,
      nftIsLoading: state.nftIsLoading
    };
  }
  
  if(action.type === 'UPDATEOWNER') {
    const index = state.collection.findIndex(NFT => NFT.id == action.id);
    let collection = [...state.collection];
    collection[index].owner = action.newOwner;

    return {
      contract: state.contract,
      totalSupply: state.totalSupply,
      nftSelling: state.nftSelling,
      collection: collection,
      ftOfAccount: state.nftOfAccount,
      nftIsLoading: state.nftIsLoading
    };
  }

  if(action.type === 'LOADING') {    
    return {
      contract: state.contract,
      totalSupply: state.totalSupply,
      nftSelling: state.nftSelling,
      collection: state.collection,
      ftOfAccount: state.nftOfAccount,
      nftIsLoading: action.loading
    };
  }
  
  return defaultCollectionState;
};

const CollectionProvider = props => {
  const [CollectionState, dispatchCollectionAction] = useReducer(collectionReducer, defaultCollectionState);
  
  const loadContractHandler = (web3, NFTCollection, deployedNetwork) => {
    const contract = deployedNetwork ? new web3.eth.Contract(NFTCollection.abi, deployedNetwork.address): '';
    
    dispatchCollectionAction({type: 'CONTRACT', contract: contract}); 
    return contract;
  };

  const loadTotalSupplyHandler = async(contract) => {
    const totalSupply = await contract.methods.gameIDCounter().call();
    dispatchCollectionAction({type: 'LOADSUPPLY', totalSupply: totalSupply});
    return totalSupply;
  };
  
  const loadNFTofAccountHandler = async(contract, account) => {
    const NFTs = await contract.methods.getAllTokens(account).call();
    dispatchCollectionAction({type: 'LOADNFTOFACCOUNT', nftOfAccount: NFTs});
    return NFTs;
  };

  const loadCollectionHandler = async( contract, nftOfAccount, account) => {
    let collection = [];

    for(let i = 0; i < nftOfAccount.length; i++) {
      
      const uri = await contract.methods.uri(nftOfAccount[i]).call();
      console.log('result222',uri);
      let result = uri.indexOf("https://");
      if(result == -1) continue;
      const amount = await contract.methods.balanceOf(account,nftOfAccount[i]).call();
      try {
        // const response = await fetch(`https://ipfs.infura.io/ipfs/${hash}?clear`);
        const response = await fetch(uri);
        if(!response.ok) {
          throw new Error('Something went wrong');
        }
       
        const metadata = await response.json();      
        collection = [{
          id: nftOfAccount[i],
          amount: amount,
          title: metadata.name,
          description: metadata.description,
          img: metadata.image,
          owner: account
        }, ...collection];
      }catch {
        console.error('Something went wrong');
      }
    }
    dispatchCollectionAction({type: 'LOADCOLLECTION', collection: collection});     
  };


  // const loadNFTsellingHandler = async( contract, nftOfAccount, account, offers) => {
    const loadNFTsellingHandler = async() => {
    // let nftSelling = [];
    
    // nftSelling = await Promise.all( offers.map(async _offer => {
    // for(let i = 0; i < nftOfAccount.length; i++) {
    //   if(_offer.id == parseInt(nftOfAccount[i]) ) {
    //     const uri = await contract.methods.uri(nftOfAccount[i]).call();
    //     // const amount = await contract.methods.balanceOf(account,nftOfAccount[i]).call();
    //     try {
    //       const response = await fetch(uri);
    //       const metadata = await response.json();
    //       let str = nftOfAccount[i].toString().padStart(4, "0")
    //       let item =  {
    //         id: nftOfAccount[i],
    //         offerId: _offer.offerId,
    //         offer : _offer,
    //         idFake:str,
    //         price:formatPrice(_offer.price).toFixed(2),
    //         amount: _offer.amount - _offer.amountsold,
    //         title: metadata.properties.name.description,
    //         description: metadata.properties.description.description,
    //         img: metadata.properties.image.description,
    //       };
    //       return item;
    //     }catch {
    //       console.error('===========>>>>');
    //     }
    //   }
      
    // }
    // }));   

    listSelling().then(res => {
      const rowTemp = res.data.entries.map( (e,index) =>{
          if(!res.data) return [];
          return {
            amount : e.item_amount, 
            available : e.item_amount -  e.item_amount, 
            description : e.item_meta_data?.description,
            idFake : e.item_id.toString().padStart(4, "0"),
            offer: e.item_offer_data,
            img : e.item_img,
            offerId : e.item_offer_data.item_offer_id,
            price : formatPrice(e.item_amount).toFixed(2),
            title : e.item_meta_data?.title,
            dataServer: e,
          }
      });
      dispatchCollectionAction({type: 'LOADSELL', nftSelling: rowTemp});
    })
    
  };

  
  const updateCollectionHandler = async(contract, id, owner) => {
    let NFT;
    const uri = await contract.methods.tokenURI(id).call();
    try {
      const response = await fetch(uri);
      if(!response.ok) {
        throw new Error('Something went wrong');      }

      const metadata = await response.json();      

      NFT = {
          id: parseInt(id),
          title: metadata.properties.name.description,
          img: metadata.properties.image.description,
          owner: '-----'
      };
    }catch {
      console.error('Something went wrong');
    }
    dispatchCollectionAction({type: 'UPDATECOLLECTION', NFT: NFT});
  };

  const updateOwnerHandler = (id, newOwner) => {
    dispatchCollectionAction({type: 'UPDATEOWNER', id: id, newOwner: newOwner});
  };

  const setNftIsLoadingHandler = (loading) => {
    dispatchCollectionAction({type: 'LOADING', loading: loading});
  };

  const collectionContext = {
    contract: CollectionState.contract,
    totalSupply: CollectionState.totalSupply,
    nftOfAccount: CollectionState.nftOfAccount,
    collection: CollectionState.collection,
    nftSelling: CollectionState.nftSelling,
    nftIsLoading:CollectionState.nftIsLoading,
    loadContract: loadContractHandler,
    loadTotalSupply: loadTotalSupplyHandler,
    loadNFTofAccount: loadNFTofAccountHandler,
    loadCollection: loadCollectionHandler,
    loadNFTselling: loadNFTsellingHandler,
    updateCollection: updateCollectionHandler,
    updateOwner: updateOwnerHandler,
    setNftIsLoading: setNftIsLoadingHandler
  };
  
  return (
    <CollectionContext.Provider value={collectionContext}>
      {props.children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;