import * as React from 'react';
import MarketplaceContext from '../../store/marketplace-context';
import { useContext  } from 'react';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import callApi from '../../api';
import { Alert, AlertTitle, Card, Chip, CssBaseline, Grid, Typography } from '@mui/material';
import NavLeft from '../Layout/NavLeft';
import { Box } from '@mui/system';
import { CheckOwner } from '../../helpers/lib';
import Web3Context from '../../store/web3-context';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

 

const ADAddressInfo = () => {
  const [rows, setRows] = React.useState([]);   
  const [receove_address, addressReceiveWETH] = React.useState("");
   const [_addressWETH, set_addressWETH] = React.useState("");
  const marketplaceCtx = useContext(MarketplaceContext);
  const web3Ctx =  useContext(Web3Context);
  const navigate = useNavigate();
  React.useEffect(() => {
    if(marketplaceCtx.contract) {
      marketplaceCtx.contract.methods._addressReceiveWETH().call().then(res => {
        addressReceiveWETH(res);
      })
      marketplaceCtx.contract.methods._addressWETH().call().then(res => {
        set_addressWETH(res);
      })
    }
   
  }, []) 
  return(

        <div>
          {marketplaceCtx.contract &&
            <div>
          
              <Stack
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <Alert severity="success" variant="outlined">
                      <AlertTitle>Receive ETH</AlertTitle>
                      <Chip label={receove_address} variant="outlined"  />
                    </Alert>
                    <Alert severity="success" variant="outlined">
                      <AlertTitle>Address NFT</AlertTitle>
                      <Chip label="0x3B5883eA952947A4FDD05Df1F51d395a9043ED72" variant="outlined"  />
                    </Alert>
                    {/* <Alert severity="success" variant="outlined">
                      <AlertTitle>ERC20 for PRICE</AlertTitle>
                      <Chip label={_addressWETH} variant="outlined"  />
                    </Alert> */}
                    <Alert severity="success" variant="outlined">
                      <AlertTitle>Address Market</AlertTitle>
                      <Chip label={marketplaceCtx.contract._address} variant="outlined"  />
                    </Alert>
                    
                </Stack>
                <Box height="20px"></Box>
                <Stack
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >

                    {/* <Alert severity="info" variant="outlined">
                      <AlertTitle>Owner</AlertTitle>
                      <Chip label={web3Ctx.account} variant="outlined"  />
                    </Alert> */}
                    {/* <Alert severity="info" variant="outlined">
                      <AlertTitle>Address Market</AlertTitle>
                      <Chip label={marketplaceCtx.contract._address} variant="outlined"  />
                    </Alert> */}
                    
                  {/* <Alert severity="info" variant="outlined">
                      <AlertTitle>Address NFT</AlertTitle>
                      <Chip label="0x3B5883eA952947A4FDD05Df1F51d395a9043ED72" variant="outlined"  />
                    </Alert>
                    
                    
                    <Alert severity="info" variant="outlined">
                      <AlertTitle>ERC20 for PRICE</AlertTitle>
                      <Chip label="0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa" variant="outlined"  />
                    </Alert> */}
                </Stack>
                </div>
                }
        </div>
  );
};

export default ADAddressInfo;