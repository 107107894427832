import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Backdrop, CardMedia, CircularProgress } from '@mui/material';
import { useContext, useRef, useState, createRef } from 'react';
import Web3Context from '../../store/web3-context';
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import ServerDataContext from '../../store/server-context';
import VNDContext from '../../store/vnd-context';
import web3 from '../../connection/web3';
import callApi from '../../api';
import { RestaurantMenu } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

export default function MyCard(props) {

  const web3Ctx = useContext(Web3Context);
  const collectionCtx = useContext(CollectionContext);
  const marketplaceCtx = useContext(MarketplaceContext);
  const [loadding, setLoadding] = useState(false); 
  const [approved, setApproved] = useState(false); 
  const priceRefs = useRef([]);
  const amountRefs = useRef([]);
  const serverDataPro = useContext(ServerDataContext);
  const { enqueueSnackbar } = useSnackbar();
  if (priceRefs.current.length !== collectionCtx.collection.length) {
    priceRefs.current = Array(collectionCtx.collection.length).fill().map((_, i) => priceRefs.current[i] || createRef());
  }
  if (amountRefs.current.length !== collectionCtx.collection.length) {
    amountRefs.current = Array(collectionCtx.collection.length).fill().map((_, i) => amountRefs.current[i] || createRef());
  }

  const web3MakeOffer = (id, enteredAmount,enteredPrice) =>{
    setLoadding(true);
    marketplaceCtx.contract.methods.makeOffer(id, enteredAmount,enteredPrice).send({ from: web3Ctx.account })
    .on('error', (error) => {
      window.alert('Something went wrong when pushing to the blockchain');
      marketplaceCtx.setMktIsLoading(false);
      setLoadding(false);
    }).on('receipt', async (receipt) => { 
      // console.log('receipt',receipt);
       const nftOfAccount = await collectionCtx.loadNFTofAccount(collectionCtx.contract, web3Ctx.account);
        collectionCtx.loadCollection(collectionCtx.contract, nftOfAccount, web3Ctx.account);

      const dataPost = {
        data:{
          "item_name": props.dataNFT.title,
          "item_amount": receipt.events.Offer.returnValues.amount,
          "item_amountsold": 0,
          "item_cancel": 0,
          "item_filled": 0,
          "item_price": receipt.events.Offer.returnValues.price,
          "item_id": props.dataNFT.id,
          "item_meta_data": props.dataNFT,
          "item_img": props.dataNFT.img,
          "item_transaction": receipt.transactionHash,
          "item_owner":receipt.events.Offer.returnValues.user,
          "item_offer_id": receipt.events.Offer.returnValues.offerId,
          "item_offer_data": receipt.events.Offer.returnValues
        }
      } 
      callApi('save/nft_selling','POST',dataPost).then(res =>{
        setLoadding(false);
        serverDataPro.loadNFTselling();
        let variant = "success";
        enqueueSnackbar('Make offer success',{variant})
      })

    })
  }

  // isApprovedForAll
  collectionCtx.contract.methods.isApprovedForAll(web3Ctx.account, marketplaceCtx.contract.options.address).call().then(res => {
    setApproved(res)
  })
  // const web3Approve = () => {
  //   collectionCtx.contract.methods.setApprovalForAll(marketplaceCtx.contract.options.address, 1).send({ from: web3Ctx.account })
  //   .on('transactionHash', (hash) => {
  //     // marketplaceCtx.setMktIsLoading(true);
  //     setLoadding(true);
  //   }).on('receipt', (receipt) => { 
  //     alert('approve success');
  //     setLoadding(false);
  //   });

  // }
  const makeOfferHandler = (event, id, key) => {
    event.preventDefault();
    if(!Number(amountRefs.current[key].current.value))
    {
      alert('amount is not number')
      return;
    }
    const enteredPrice = web3.utils.toWei(priceRefs.current[key].current.value, 'ether');
    const enteredAmount = amountRefs.current[key].current.value

    if(approved) {
      web3MakeOffer(id, enteredAmount,enteredPrice);
      return;
    }

    collectionCtx.contract.methods.setApprovalForAll(marketplaceCtx.contract.options.address, 1).send({ from: web3Ctx.account })
    .on('transactionHash', (hash) => {
      marketplaceCtx.setMktIsLoading(true);
      setLoadding(true);
    })
    .on('receipt', (receipt) => { 
      setLoadding(false);
          //======
          web3MakeOffer(id, enteredAmount,enteredPrice);
      //======
    });

  };
  
  const addSelling = (data)=>{
    return callApi('save/nft_selling','POST',data);
  }


  return (
    <Box sx={{ minWidth: 275 }} >
       <Card variant="outlined" sx={{display: 'flex',borderRadius:2,border:0, padding:0.8, background:'#28456e7d'}}>
       <CardMedia
            component="img"
            sx={{ width: 120 }}
            image={`${props.dataNFT.img}`}
            alt=""
        />
      <React.Fragment>
        <CardContent>
        {/* <Typography sx={{ fontSize: 18,fontWeight:800,  }}  gutterBottom>
         #{props.dataNFT.idFake} 
        </Typography> */}
         <Typography sx={{ fontSize: 15, fontWeight: 800, textAlign:'center' }} color="text.primary" gutterBottom>
           {props.dataNFT.title}
        </Typography>

        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         Amount:  {props.dataNFT.amount}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
        Description: {props.dataNFT.description}
        </Typography> */}
        <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
         Price:  {props.dataNFT.price} ETH
        </Typography>
        <form className="row g-2" onSubmit={(e) => makeOfferHandler(e, props.dataNFT.id, props.dataNFT.key)}>                
            <div className="col-12">
              <input
                type="number"
                step="0.01"
                placeholder="ETH..."
                className="form-control"
                ref={priceRefs.current[props.dataNFT.key]}
              />
            </div>      

            <div className="col-12">
              <input
                type="number"
                step="0.01"
                placeholder="Amount"
                className="form-control"
                ref={amountRefs.current[props.dataNFT.key]}
              />
            </div>

            <div className="col-12 d-grid gap-2">
              <button type="submit" className="btn btn-success">Make OFFER</button>
            </div>                               
          </form> 
        </CardContent>
        <Backdrop open={loadding}>
                   <CircularProgress  />
          </Backdrop>
    </React.Fragment>
      </Card>
    </Box>
  );
}