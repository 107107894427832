import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
// import web3 from '../../../connection/web3';
// import Web3Context from '../../../store/web3-context';
// import CollectionContext from '../../../store/collection-context';
// import { GridColDef } from '@mui/x-data-grid';

// import MarketplaceContext from '../../../store/marketplace-context';
// import { formatPrice } from '../../../helpers/utils';
import { DataGrid } from '@mui/x-data-grid';
import callApi from '../../../api';
import { formatUSD } from '../../../helpers/utils';



// let rows: GridRowsProp = [];

let columns = [
    // { field: 'id', headerName: 'id', width: 80 },
    { field: 'transtions_id', headerName: 'Transtions', width: 280 },
    { field: 'address_from', headerName: 'From', width: 280 },
    { field: 'buy_amount', headerName: 'Quantity', width: 120, align:"center" },
    // { field: 'address_to', headerName: 'address_to', width: 150 },
    // { field: 'data', headerName: 'data', width: 150 },
    // { field: 'type', headerName: 'type', width: 100 },
    { field: 'code_ref', headerName: 'Code', width: 200 },
  
    { field: 'buy_price', headerName: 'Price ETH', width: 150 },
    { field: 'buy_usd', headerName: 'Price USD', width: 150   },
  ];

 const getListTradeForCard = (offerID)=>{
   const fillter = {
    filter: {offer_id:offerID},
    sort: {_created:-1},
   }
  return callApi('get/history_transaction','POST', fillter);
  // console.log('rows',rows);
 }
  

const ListTrade = (props) => {
  const [rows, setRows] = useState([]);  
  const [loadding, setLoading] = useState(false);  
   useEffect(() => {
    setLoading(true);
    getListTradeForCard(props.dataNFT.offer.offerId).then(async res => {
      const rowsT = res.data.entries.map( (e,index) =>{
           e.id = index;
           return {...e}
       });
       setRows(rowsT);
       setLoading(false);
     })
  }, [getListTradeForCard]) 

  return(
    <div  style={{ height: 380, width: '100%'}}>
      {
        <DataGrid 
            rows={rows}
              columns={columns} 
              disableColumnMenu={true}
              // hideFooter={true}
              loading={loadding}
              sx={{
                  fontSize:"1.2em", 
                  border:0,
                  '& .MuiDataGrid-cell': {
                      border: '0px',
                      fontSize: '17px'
                    },
              }}
              disableSelectionOnClick
              disableVirtualization
              headerHeight={60}
            disableColumnFilter={true}    />
      }
  </div>
  );
};

export default ListTrade;