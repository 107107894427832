import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';

import Web3Provider from './store/Web3Provider';
import CollectionProvider from './store/CollectionProvider';
import MarketplaceProvider from './store/MarketplaceProvider';
import VNDProvider from './store/VNDProvider';
import App from './App';
import './custom.css';
import ServerDataProvider from './store/ServerDataProvider';

ReactDOM.render(
  <Web3Provider>
    <ServerDataProvider>
    <CollectionProvider>
      <MarketplaceProvider>
        <VNDProvider>
        <App />
        </VNDProvider>
      </MarketplaceProvider>
    </CollectionProvider>
    </ServerDataProvider>
  </Web3Provider>, 
  document.getElementById('root')
);