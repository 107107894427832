import { useContext  } from 'react';

// import MintForm from './MintNFT/MintForm';
import NFTCollection from './NFTCollection/NFTCollection';
import CollectionContext from '../../store/collection-context';
import MarketplaceContext from '../../store/marketplace-context';
import Spinner from '../Layout/Spinner';
// import logo from '../../img/logo2.PNG'
// import NFTCollectionForSell from './NFTCollection/NFTCollectionForSell';
// import Market from './Market/Market';
// import NavHeader from '../Layout/NavHeader';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from '../Pages/Home';
import Mint from '../Pages/Mint';
import YourCard from '../Pages/YourCard';
import Login from '../Pages/Login';
import ADDashboard from '../Pages/ADDashboard';
import ADYourCard from '../Pages/ADYourCard';
import CardDetail from '../Pages/CardDetail';
import Web3Context from '../../store/web3-context';
import ADMint from '../Pages/ADMint';
import ADNFT from '../Pages/ADNFT';
import ADSetting from '../Pages/ADSetting';
import ADTransaction from '../Pages/ADTransaction';

const Main = () => {
    const web3Ctx = useContext(Web3Context);
    const marketplaceCtx = useContext(MarketplaceContext);
    const collectCtx = useContext(CollectionContext);

  return(
    <div className="">
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/your-card" element={<YourCard/>}></Route>
            <Route path="/mint" element={<Mint/>}></Route>
            <Route path="/Login" element={<Login/>}></Route>
            <Route path="/ad-dashboard" element={<ADDashboard/>}></Route>
            <Route path="/ad-nft" element={<ADNFT/>}></Route>
            {/* <Route path="/ad-your-card" element={<ADYourCard/>}></Route> */}
            <Route path="/ad-setting" element={<ADSetting/>}></Route>
            {/* <Route path="/ad-transaction" element={<ADTransaction/>}></Route> */}
            {collectCtx.nftSelling && <Route path="/card/:id" element={<CardDetail />}></Route> }
          </Routes>
      {/* {marketplaceCtx.mktIsLoading && <Spinner />} */}
    </div>
  );
};

export default Main;