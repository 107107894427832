import * as React from 'react';

import { useContext  } from 'react';
import { useNavigate ,useLocation} from 'react-router-dom'
import NavHeader from '../Layout/NavHeader';
import { useParams } from 'react-router';
import CollectionContext from '../../store/collection-context';
import { Button, CardMedia, Typography } from '@mui/material';
import {  Box } from '@mui/system';
import BasicModal from '../materials/Modal';
import ListTrade from '../Content/Market/ListTrade';
import { ArrowBackIos } from '@mui/icons-material';

// import web3 from '../../connection/web3';
import Web3Context from '../../store/web3-context';
import ServerDataContext from '../../store/server-context';
import { formatUSD } from '../../helpers/utils';
import ServerDataProvider from '../../store/ServerDataProvider';


const CardDetail = (props) => {
    const { id } = useParams()
    const nav = useNavigate()
    const collectionCtx = useContext(CollectionContext);
    const sevrerPro = useContext(ServerDataContext);
    const NFTs = sevrerPro.nftSelling.filter( e => { return e.offer.offerId == id } );
    const NFT = NFTs[0];
  // NFT.priceUSD = sevrerPro.ethPirce * parseFloat(NFT.price);
  const search = useLocation().search;
  const [parrms,setParams] = React.useState('')
  React.useEffect(() => {
    const referer_code = new URLSearchParams(search).get('referer_code');
    if(referer_code){
      setParams('?referer_code='+referer_code);
    }
    }, [])

  return(
    <main role="main" className="col-lg-12  mb-2">
        <NavHeader></NavHeader>
        <div className="container" >
        <Box height={30} />
        <Button variant="text" size="large" onClick={()=>{nav('/'+parrms)}} startIcon={<ArrowBackIos />}>Back</Button>
        <Box height={20} />
        {NFT &&
         <div className="row p-3" > 
       
              <div className="col-md-12 p-3" style={{borderRadius:20,border:'1px solid rgb(30, 73, 118)', background:"rgb(0, 30, 60)"}}>
              <div className="row" >
              
                <div className="col-md-3"  >
                <CardMedia
                        component="img"
                        image={`${NFT.img}`}
                        alt=""
                    /> 
                </div>
                <div className="col-md-8 p-3 " >
                    {/* <h4 style={{color:"#eee"}}>#{NFT.idFake}</h4> */}
                    <Typography noWrap variant="h4" gutterBottom component="div">
                    {NFT.title}
                    </Typography>
                    <p style={{color:"#ddd"}}>
                        {NFT.available  } available
                    </p>
                    <div  className="mt-4 mb-4 " > 
                       <div style={{fontSize:"35px" ,fontWeight:800}}> <label style={{fontSize:"18px"}} className="p-2"> Price:  </label> 
                       {NFT.price} <img width="26" src='https://storage.opensea.io/files/265128aa51521c90f7905e5a43dcb456_new.svg' /></div>
                       
                       <Typography sx={{ fontSize: 18, paddingLeft:1 }} color="text.secondary" gutterBottom>
                          {formatUSD(sevrerPro.ethPirce * parseFloat(NFT.price)) }
                      </Typography>
                     </div>
                     
                     {(collectionCtx.contract && NFT.available > 0) &&
                      <BasicModal  dataNFT={NFT} ></BasicModal>
                     }
                     
                     {!NFT.available &&
                      <Button variant="contained" disabled style={{width:"200px",fontSize:"23px",borderRadius:10, height:"60px"}}  >
                         <div style={{color:'white',fontWeight:800}}>BUY</div> </Button>
                     }
                     
                     <Box height="15px" ></Box>
                    <Typography variant="body"  gutterBottom>
                      <i>
                     {NFT.description}
                      </i>
                    </Typography>
                </div>
                </div>

              </div>
            <Box height="20px" ></Box>
              <div className="col-md-12 p-4" style={{borderRadius:20, border:'1px solid rgb(30, 73, 118)', background:"rgb(0, 30, 60)"}}>
                <Typography variant="h5" gutterBottom component="div">
                    Trading history
                  </Typography>
                  <Box padding={2}>
                  <ListTrade  dataNFT={NFT} ></ListTrade>
                  </Box>
                 
              </div>
        </div>}

     
        </div>
        <Box height="20px" ></Box>
    </main>
  );
};

export default CardDetail;