import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardMedia, Link } from '@mui/material';

export default function MyCard(props) {

  return (
    <Box sx={{ minWidth: 255 }} >
      <Card variant="outlined" sx={{borderRadius:5,border:0, padding:1, background:'#28456e7d'}}>
      <React.Fragment>
        <CardContent>
        <CardMedia
            component="img"
            height="330"
            image={`${props.dataNFT.img}`}
            alt=""
        />
        <Box height={20}></Box>
         <Typography sx={{ fontSize: 21, fontWeight: 800, textAlign:'center' }} color="text.primary" gutterBottom>
           {props.dataNFT.title}
        </Typography>

        <Typography sx={{ fontSize: 16 }} color="text.secondary" >
         Amount:  {props.dataNFT.amount}
        </Typography>
        </CardContent>
        <Link underline="none" className="btn btn-dark" href={`${process.env.REACT_APP_OPENSEA}${props.dataNFT.id}`} target={'_blank'} variant="body2">
          See OpenSea 
        </Link>

    </React.Fragment>
      </Card>
    </Box>
  );
}