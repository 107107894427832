import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Web3Context from '../../store/web3-context';
import { useContext  } from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ModalGuideBuyOld(props) {
  // console.log('props',props.data);
  return (

      <div>
            <div className="row p-1" >
              <Box height={10}></Box>
              <div className="col-md-12">
                <div >
                    <Stack spacing={1} direction={'row'}  alignItems={{md:'center'}}>
                      <div style={{fontSize:'1em'}}> Your balance (Matic) :</div>
                      <div>
                        <div className='alert-unlock'>{(props.data) ? props.data : 0}
                          <img src="https://cryptologos.cc/logos/polygon-matic-logo.png" style={{marginLeft:10}} width={20} />
                        </div>
                        {props.data < 0.005 &&
                           <lable style={{color:'red',fontSize:'0.8em'}}> <i>*Matic in your wallet is not enough.</i></lable>
                        }
                       
                      </div>
                    </Stack>
                </div>
               
                <Box height={30}></Box>
    
                <Stack spacing={2} direction={{xs:'column',md:'row'}}  alignItems={{md:'center'}}  >
                <label  style={{color:'#1854ad',textAlign:'center', fontSize:'1em'}}>Buy Matic at: </label>

                    <Button variant='outlined' color="warning" size="large" >
                      <Link href='https://www.binance.com/en/buy-polygon-matic' target={'_blank'}>
                      <img height={25}  src="/imgs/binance_logo.png" />
                      </Link>
                    </Button>
                    <Button variant='outlined' color="secondary" size="large">
                    <Link href='https://www.moonpay.com/buy/matic' target={'_blank'}>
                      <img height={25} src='/imgs/moonpay.svg' />
                      </Link>
                    </Button>
                </Stack>
                <Box height={30}></Box>
                <div className="col-md-12 " style={{padding:10}}>
                  <div style={{fontSize:'1.3em', fontWeight:'bold',marginBottom:'10px'}}>
                    Note:
                  </div>
                <Stack spacing={0.5} direction={'column'} style={{color:'blue',fontSize:'0.9em'}}> 
                        <i>* Matic is a required transaction fee when transacting on the Polygon network.</i>
                        <i>* Recommend you should have at least 0.005 Matic in your wallet to make a transaction.</i>
                </Stack>
                </div>
              
                
              </div>
            </div>
      </div>
   


  );
}